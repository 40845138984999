import React from 'react';
import { styled, keyframes } from '@stitches/react';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import {BlockTypeProps} from "../../../../typings/interfaces-v1/block-type-props";
import {MenuItemsProps} from "../../../../typings/interfaces-v1/practice-front-node.interface";
import {Link} from "react-scroll";
import ThemeStore from "../../../../stores/theme-store";
import { EASE_COLORS } from '../../../../constants/colors';
import { HamburgerIcon } from '@easepractice/icons';

const slideUpAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateY(2px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideRightAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateX(-2px)' },
    '100%': { opacity: 1, transform: 'translateX(0)' },
});

const slideDownAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateY(-2px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideLeftAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateX(2px)' },
    '100%': { opacity: 1, transform: 'translateX(0)' },
});

const StyledContent = styled(DropdownMenuPrimitive.Content, {
    width: '90vw',
    minWidth:'300px',
    backgroundColor: '$white',
    borderRadius: 6,
    padding: 5,
    marginTop:'.7rem',
    boxShadow:
        '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
    '@media (prefers-reduced-motion: no-preference)': {
        animationDuration: '400ms',
        animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
        willChange: 'transform, opacity',
        '&[data-state="open"]': {
            '&[data-side="top"]': { animationName: slideDownAndFade },
            '&[data-side="right"]': { animationName: slideLeftAndFade },
            '&[data-side="bottom"]': { animationName: slideUpAndFade },
            '&[data-side="left"]': { animationName: slideRightAndFade },
        },
    },
});

const itemStyles = {
    all: 'unset',
    fontSize: 13,
    lineHeight: 1,
    color: EASE_COLORS.PRIMARY_COLOR,
    borderRadius: 3,
    display: 'flex',
    alignItems: 'center',
    height: 25,
    padding: '0 5px',
    position: 'relative',
    paddingLeft: 25,
    userSelect: 'none',

    '&[data-disabled]': {
        color: EASE_COLORS.DISABLE_BUTTON,
        pointerEvents: 'none',
    },

    '&:focus': {
        backgroundColor: EASE_COLORS.BACKGROUND,
        color: EASE_COLORS.PRIMARY_COLOR,
    },
};

const StyledRadioItem = styled(DropdownMenuPrimitive.RadioItem, { ...itemStyles });


const StyledDropDownItem = styled(DropdownMenuPrimitive.Item, {
    padding:'.6rem .5rem',
});


const StyledSeparator = styled(DropdownMenuPrimitive.Separator, {
    height: 1,
    backgroundColor: '$background',
    margin: 5,
});



// Exports
export const DropdownMenu = DropdownMenuPrimitive.Root;
export const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;
export const DropdownMenuContent = StyledContent;
export const DropdownMenuItem = StyledDropDownItem;
export const DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup;
export const DropdownMenuRadioItem = StyledRadioItem;
export const DropdownMenuSeparator = StyledSeparator;



const Box = styled('div', {});

const IconButton = styled('button', {
    all: 'unset',
    fontFamily: 'inherit',
    borderRadius: '100%',
    height: 45,
    width: 45,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '$primary',
    backgroundColor: '$white',
    '&:hover': { boxShadow: `0 2px 10px $black`, backgroundColor: '$primary' , outline:"none", border:'none'},
    '&:focus': { outline:"none", border:'none'},
});

export const HamburgerMenu:React.FC<BlockTypeProps> = ({blockNode}) => {
    const { theme} = ThemeStore( (state)=>({theme: state.theme}))

    let colorPalettes = theme?.themeSettings?.colorPalettes || {
        primary:  EASE_COLORS.PRIMARY_COLOR,
        secondary: EASE_COLORS.SECONDARY_COLOR,
        warn: EASE_COLORS.WARNING_COLOR,
        info:  EASE_COLORS.WHITE,
    }
    let fonts = theme?.themeSettings?.fonts || {
        title: 'Noto Serif',
        body: 'Work Sans',
        subtitle: 'Work Sans',
        paragraph: 'Noto Serif',
    };

    const {warn}= colorPalettes as any;
    const {body:bodyFont}= fonts as any;

    return (
        <Box>
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <IconButton aria-label="Customise options" >
                        <HamburgerIcon style={{ width:"25px", height:"25px"}}/>
                    </IconButton>
                </DropdownMenuTrigger>

                <DropdownMenuContent sideOffset={2}>
                    {blockNode && blockNode.data && blockNode.data.metadata?.menus.map((menu: MenuItemsProps) => (
                               <div key={menu.sectionId} style={{color: warn, fontFamily:bodyFont}}>
                                   <Link activeClass="active"
                                         to={menu.title.toLocaleLowerCase()}
                                         spy={true}
                                         smooth={true}
                                         offset={-90}
                                         duration={100}
                                         delay={100}
                                         isDynamic={true}
                                         ignoreCancelEvents={false}
                                   >
                                   <DropdownMenuItem>
                                        {menu.title}
                                   </DropdownMenuItem>
                                    </Link>
                                       {/*</DropdownMenuTriggerItem>*/}
                        <DropdownMenuSeparator />
                        </div>

                       ))}

                </DropdownMenuContent>
            </DropdownMenu>
        </Box>
    );
};

export default HamburgerMenu;

