import React from 'react';
import { BlockTypeProps } from '../../../../typings/interfaces/block-type-props';
import { styled} from "../../../../styles/stitches.config";
import placeholderImage from '../../../../assets/images/aboutMe.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
const Div= styled('div',{
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    borderRadius:4,

})
const CoverImage: React.FC<BlockTypeProps> = ({blockNode}) => {
    let dynamicStyles: any = {};
    if (blockNode.design.styles){
        const temp = JSON.parse(JSON.stringify(blockNode.design.styles))
        for (const key in temp){
            if (temp[key].type && temp[key].isInner){
                dynamicStyles[key] =temp[key].value
            } 
        }
    }

    const imageUrl= blockNode?.data?.image?.value || '';


    return   (
        <>
            <Div id={blockNode?._id}
                 style={{
                     backgroundImage: `url("${imageUrl}")`
                 }}
                 css={{...dynamicStyles}}
            />
         </>
    )
}

export default CoverImage;