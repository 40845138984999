import React, {Fragment} from 'react';
import NodeRenderer from "./Components/NodeRenderer";
import {styled} from "../../styles/stitches.config";
import useThemeStore from "../../stores/theme-store";
import useWebFontLoader from "../../hooks/useWebFontLoader";
import {values} from "lodash";
import PageLoader from "../../components/PageLoader";


const Content= styled('div',{
    left:'0px',
    right:'0px',
    overflow:'auto',
})

const PageLoaderWrapper= styled('div',{
    width: '100%',
    height: '85vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
})

const  Index:React.FC =()=> {
    const {theme} = useThemeStore(state=>({theme: state.theme}));
    useWebFontLoader(['Libre Baskerville','Manrope']);

    return (
         <Fragment>
             <Content>
                 {
                     theme ?  <NodeRenderer node={JSON.parse(JSON.stringify(theme))}/> :
                         <PageLoaderWrapper>
                             <PageLoader/>
                         </PageLoaderWrapper>

                 }
             </Content>

         </Fragment>
    );
}

export default  React.memo(Index);
