import Hero from "./HeroContent";
import FooterContent from "./FooterContent";
import SiteHeader from "./SiteHeader";
import ServiceSectionTitle from "./ServiceSection/serviceSectionTitle";
import FaqHeader from "./Faq/FaqHeader";
import FAQs from "./Faq/FAQs";
import AboutMeDetails from "./AboutMe/aboutMeDetails";
import AboutMeBanner from "./AboutMe/aboutMeBanner";
import ContactInfo from "./Contact/ContactInfo";
import Emergency from "./Emergency/Emergency";
import PricingHeader from "./Pricing/PricingHeader";
import ServiceSectionDetails from "./ServiceSection/serviceSectionDetails";
import SimpleOrderedList from "./SimpleOrderedList";
import PriceCard from "./Pricing/PriceCard";

export const BlockTypeComponentMap: any =  {
    "service-section-details-t1": ServiceSectionDetails,
    "service-section-title": ServiceSectionTitle,
    "practice-front-hero": Hero,
    "practice-front-about-me-details": AboutMeDetails,
    "practice-front-about-me-banner": AboutMeBanner,
    "practice-front-footer": FooterContent,
    "practice-front-header": SiteHeader,
    "practice-front-faq-header": FaqHeader,
    "accordian": FAQs,
    "practice-front-contact": ContactInfo,
    "practice-front-emergency": Emergency,
    "practice-front-pricing-header": PricingHeader,
    "price-card": PriceCard,
    "simple-ordered-list": SimpleOrderedList
}