import React from 'react';

import Background from './background';
import Icon from './icon';
import Mask from './mask';
import { keyFor } from './networks';
import { socialContainer, socialSvg, socialIcon } from './styles';

function getNetworkKey(props: { url: any; network: any; }) {
    return props.network || keyFor(props.url);
}

interface SocialIconProps {
    network?: string;
    url: string;
    size?: number;
    style?: React.CSSProperties;
}

const SocialIcon: React.FC<SocialIconProps> = (props) => {
    const { url, network, size } = props;
    const networkKey = getNetworkKey({ url, network });

    const sizecss: React.CSSProperties = {};

    if (size) {
        sizecss.height = size + 'px';
        sizecss.width = size + 'px';
    }

    return (
        <div style={{ ...socialIcon, ...sizecss, ...props.style }}>
            <div className="social-container" style={socialContainer}>
                <svg className="social-svg" style={socialSvg} viewBox="0 0 64 64">
                    <Background />
                    <Icon networkKey={networkKey} />
                    <Mask networkKey={networkKey} />
                </svg>
            </div>
        </div>
    );
};

export default SocialIcon;
