import { CSS } from "../../styles/stitches.config";
import { VariantType } from "./types";


const DisplayH164RegularStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '4rem',
    lineHeight: '74px',
    color: '$textDefault',
    fontFamily: '$regolaPro',
    letterSpacing: '-0.02em',
};

const DisplayH164MediumStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '4rem',
    lineHeight: '74px',
    color: '$textDefault',
    fontFamily: '$regolaPro',
    letterSpacing: '-0.02em',
};

const DisplayH164BoldStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '4rem',
    lineHeight: '74px',
    color: '$textDefault',
    fontFamily: '$regolaPro',
    letterSpacing: '-0.02em',
};

const DisplayH252RegularStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '3.25rem',
    lineHeight: '64px',
    color: '$textDefault',
    fontFamily: '$regolaPro',
    letterSpacing: '-0.02em',
};

const DisplayH252MediumStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '3.25rem',
    lineHeight: '64px',
    color: '$textDefault',
    fontFamily: '$regolaPro',
    letterSpacing: '-0.02em',
};

const DisplayH252BoldStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '3.25rem',
    lineHeight: '64px',
    color: '$textDefault',
    fontFamily: '$regolaPro',
    letterSpacing: '-0.02em',
};

const DisplayH344RegularStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '2.75rem',
    lineHeight: '48px',
    color: '$textDefault',
    fontFamily: '$regolaPro',
    letterSpacing: '-0.02em',
};

const DisplayH344MediumStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '2.75rem',
    lineHeight: '48px',
    color: '$textDefault',
    fontFamily: '$regolaPro',
    letterSpacing: '-0.02em',
};

const DisplayH344BoldStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '2.75rem',
    lineHeight: '48px',
    color: '$textDefault',
    fontFamily: '$regolaPro',
    letterSpacing: '-0.02em',
};

const TitleH140RegularStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '2.5rem',
    lineHeight: '48px',
    color: '$textDefault',
    fontFamily: '$regolaPro',
    letterSpacing: '-0.02em',
};
const TitleH140MediumStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '2.5rem',
    lineHeight: '48px',
    color: '$textDefault',
    fontFamily: '$regolaPro',
    letterSpacing: '-0.02em',
};
const TitleH140BoldStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '2.5rem',
    lineHeight: '48px',
    color: '$textDefault',
    fontFamily: '$regolaPro',
    letterSpacing: '-0.02em',
};

const TitleH236RegularStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '2.25rem',
    lineHeight: '43px',
    color: '$textDefault',
    fontFamily: '$regolaPro',
    letterSpacing: '-0.02em',
};

const TitleH236MediumStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '2.25rem',
    lineHeight: '43px',
    color: '$textDefault',
    fontFamily: '$regolaPro',
    letterSpacing: '-0.02em',
};

const TitleH236BoldStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '2.25rem',
    lineHeight: '43px',
    color: '$textDefault',
    fontFamily: '$regolaPro',
    letterSpacing: '-0.02em',
};

const TitleH332RegularStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '2rem',
    lineHeight: '38px',
    color: '$textDefault',
    fontFamily: '$regolaPro',
    letterSpacing: '-0.02em',
};

const TitleH332MediumStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '2rem',
    lineHeight: '38px',
    color: '$textDefault',
    fontFamily: '$regolaPro',
    letterSpacing: '-0.02em',
};

const TitleH332BoldStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '2rem',
    lineHeight: '38px',
    color: '$textDefault',
    fontFamily: '$regolaPro',
    letterSpacing: '-0.02em',
};

const TitleH428RegularStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1.75rem',
    lineHeight: '34px',
    color: '$textDefault',
    fontFamily: '$regolaPro',
    letterSpacing: '-0.02em',
};
const TitleH428MediumStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '1.75rem',
    lineHeight: '34px',
    color: '$textDefault',
    fontFamily: '$regolaPro',
    letterSpacing: '-0.02em',
};

const TitleH428BoldStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '1.75rem',
    lineHeight: '34px',
    color: '$textDefault',
    fontFamily: '$regolaPro',
    letterSpacing: '-0.02em',
};

const TitleH524RegularStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1.5rem',
    lineHeight: '34px',
    color: '$textDefault',
    fontFamily: '$regolaPro',
    letterSpacing: '-0.02em',
};
const TitleH524MediumStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '1.5rem',
    lineHeight: '34px',
    color: '$textDefault',
    fontFamily: '$regolaPro',
    letterSpacing: '-0.02em',
};

const TitleH524BoldStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '1.5rem',
    lineHeight: '34px',
    color: '$textDefault',
    fontFamily: '$regolaPro',
    letterSpacing: '-0.02em',
};

const TitleH620RegularStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1.25rem',
    lineHeight: '24px',
    color: '$textDefault',
    fontFamily: '$regolaPro',
    letterSpacing: '-0.02em',
};
const TitleH620MediumStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '1.25rem',
    lineHeight: '24px',
    color: '$textDefault',
    fontFamily: '$regolaPro',
    letterSpacing: '-0.02em',
};

const TitleH620BoldStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '1.25rem',
    lineHeight: '24px',
    color: '$textDefault',
    fontFamily: '$regolaPro',
    letterSpacing: '-0.02em',
};

const Subtitle18RegularStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1.125rem',
    lineHeight: '24px',
    color: '$textDefault',
    fontFamily: '$inter',
    letterSpacing: '-0.02em',
};

const Subtitle18MediumStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '1.125rem',
    lineHeight: '24px',
    color: '$textDefault',
    fontFamily: '$inter',
    letterSpacing: '-0.02em',
};
const Subtitle18SemiBoldStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '1.125rem',
    lineHeight: '24px',
    color: '$textDefault',
    fontFamily: '$inter',
    letterSpacing: '-0.02em',
};
const Subtitle18BoldStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '1.125rem',
    lineHeight: '24px',
    color: '$textDefault',
    fontFamily: '$inter',
    letterSpacing: '-0.02em',
};
const Subtitle18RegularUnderlineStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1.125rem',
    lineHeight: '24px',
    color: '$textDefault',
    fontFamily: '$inter',
    letterSpacing: '-0.02em',
    textDecorationLine: 'underline',
};

const Subtitle20BoldStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '30px',
    color: '$textDefault',
    fontFamily: '$inter',
};

const Body14RegularStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '.875rem',
    lineHeight: '20px',
    color: '$textDefault',
    fontFamily: '$inter',
};
const Body14MediumStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '.875rem',
    lineHeight: '20px',
    color: '$textDefault',
    fontFamily: '$inter',
};
const Body14SemiBoldStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '.875rem',
    lineHeight: '20px',
    color: '$textDefault',
    fontFamily: '$inter',
};
const Body14BoldStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '.875rem',
    lineHeight: '20px',
    color: '$textDefault',
    fontFamily: '$inter',
};
const Body14RegularUnderlineStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '.875rem',
    lineHeight: '20px',
    color: '$textDefault',
    fontFamily: '$inter',
    textDecorationLine: 'underline',
};

const Body14RSemiboldUnderlineStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '.875rem',
    lineHeight: '20px',
    color: '$textDefault',
    fontFamily: '$inter',
    textDecorationLine: 'underline',
};

const Body16RegularStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '24px',
    color: '$textDefault',
    fontFamily: '$inter',
};
const Body16MediumStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: '24px',
    color: '$textDefault',
    fontFamily: '$inter',
};
const Body16SemiBoldStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: '24px',
    color: '$textDefault',
    fontFamily: '$inter',
};
const Body16BoldStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '24px',
    color: '$textDefault',
    fontFamily: '$inter',
};
const Body16RegularUnderlineStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '24px',
    color: '$textDefault',
    fontFamily: '$inter',
    textDecorationLine: 'underline',
};
const Body16SemiBoldUnderlineStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: '24px',
    color: '$textDefault',
    fontFamily: '$inter',
    textDecorationLine: 'underline',
};

const Caption12RegularStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '.75rem',
    lineHeight: '18px',
    color: '$textDefault',
    fontFamily: '$inter',
};
const Caption12MediumStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '.75rem',
    lineHeight: '18px',
    color: '$textDefault',
    fontFamily: '$inter',
};

const Caption12SemiBoldStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '.75rem',
    lineHeight: '18px',
    color: '$textDefault',
    fontFamily: '$inter',
};

const Caption12BoldStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '.75rem',
    lineHeight: '18px',
    color: '$textDefault',
    fontFamily: '$inter',
};

const Caption12RegularUnderlineStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '.75rem',
    lineHeight: '18px',
    color: '$textDefault',
    fontFamily: '$inter',
    textDecorationLine: 'underline',
};

const Caption12SemiBoldUnderlineStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '.75rem',
    lineHeight: '18px',
    color: '$textDefault',
    fontFamily: '$inter',
    textDecorationLine: 'underline',
};

const Caption10RegularStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '.625rem',
    lineHeight: '16px',
    color: '$textDefault',
    fontFamily: '$inter',
};
const Caption10MediumStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '.625rem',
    lineHeight: '16px',
    color: '$textDefault',
    fontFamily: '$inter',
};

const Caption10SemiBoldStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '.625rem',
    lineHeight: '16px',
    color: '$textDefault',
    fontFamily: '$inter',
};

const Caption10BoldStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '.625rem',
    lineHeight: '16px',
    color: '$textDefault',
    fontFamily: '$inter',
};

const Caption10RegularUnderlineStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '.625rem',
    lineHeight: '16px',
    color: '$textDefault',
    fontFamily: '$inter',
    textDecorationLine: 'underline',
};
const Caption10SemiBoldUnderlineStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '.625rem',
    lineHeight: '16px',
    color: '$textDefault',
    fontFamily: '$inter',
    textDecorationLine: 'underline',
};

const Subtitle20MediumStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '30px',
    color: '$textDefault',
    fontFamily: '$inter',
};

const TitleH324BoldStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '32px',
    color: '$textDefault',
    fontFamily: '$regolaPro',
};
const TitleH264BoldStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '64px',
    lineHeight: '78px',
    color: '$textDefault',
    fontFamily: '$regolaPro',
};

const TitleH172BoldStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '72px',
    lineHeight: '87px',
    color: '$textDefault',
    fontFamily: '$regolaPro',
};

const TitleH232BoldStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '32px',
    lineHeight: '40px',
    color: '$textDefault',
    fontFamily: '$regolaPro',
};

const TitleH142BoldStyle: CSS = {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '42px',
    lineHeight: '58px',
    color: '$textDefault',
    fontFamily: '$regolaPro',
};

export const getTypographyStyle = (variant: VariantType): CSS => {
    switch (variant) {
        case 'display-h1-64-regular':
            return DisplayH164RegularStyle;
        case 'display-h1-64-medium':
            return DisplayH164MediumStyle;
        case 'display-h1-64-bold':
            return DisplayH164BoldStyle;

        case 'display-h2-52-regular':
            return DisplayH252RegularStyle;
        case 'display-h2-52-medium':
            return DisplayH252MediumStyle;
        case 'display-h2-52-bold':
            return DisplayH252BoldStyle;

        case 'display-h3-44-regular':
            return DisplayH344RegularStyle;
        case 'display-h3-44-medium':
            return DisplayH344MediumStyle;
        case 'display-h3-44-bold':
            return DisplayH344BoldStyle;

        case 'title-h1-40-regular':
            return TitleH140RegularStyle;
        case 'title-h1-40-medium':
            return TitleH140MediumStyle;
        case 'title-h1-40-bold':
            return TitleH140BoldStyle;

        case 'title-h2-36-regular':
            return TitleH236RegularStyle;
        case 'title-h2-36-medium':
            return TitleH236MediumStyle;
        case 'title-h2-36-bold':
            return TitleH236BoldStyle;

        case 'title-h3-32-regular':
            return TitleH332RegularStyle;
        case 'title-h3-32-medium':
            return TitleH332MediumStyle;
        case 'title-h3-32-bold':
            return TitleH332BoldStyle;

        case 'title-h4-28-regular':
            return TitleH428RegularStyle;
        case 'title-h4-28-medium':
            return TitleH428MediumStyle;
        case 'title-h4-28-bold':
            return TitleH428BoldStyle;

        case 'title-h5-24-regular':
            return TitleH524RegularStyle;
        case 'title-h5-24-medium':
            return TitleH524MediumStyle;
        case 'title-h5-24-bold':
            return TitleH524BoldStyle;

        case 'title-h6-20-regular':
            return TitleH620RegularStyle;
        case 'title-h6-20-medium':
            return TitleH620MediumStyle;
        case 'title-h6-20-bold':
            return TitleH620BoldStyle;

        case 'subtitle-18-regular':
            return Subtitle18RegularStyle;
        case 'subtitle-18-medium':
            return Subtitle18MediumStyle;
        case 'subtitle-18-semibold':
            return Subtitle18SemiBoldStyle;
        case 'subtitle-18-bold':
            return Subtitle18BoldStyle;
        case 'subtitle-18-regular-underline':
            return Subtitle18RegularUnderlineStyle;

        case 'body-14-regular':
            return Body14RegularStyle;
        case 'body-14-medium':
            return Body14MediumStyle;
        case 'body-14-semi-bold':
            return Body14SemiBoldStyle;
        case 'body-14-bold':
            return Body14BoldStyle;
        case 'body-14-regular-underline':
            return Body14RegularUnderlineStyle;
        case 'body-14-semibold-underline':
            return Body14RSemiboldUnderlineStyle;

        case 'body-16-regular':
            return Body16RegularStyle;
        case 'body-16-medium':
            return Body16MediumStyle;
        case 'body-16-semi-bold':
            return Body16SemiBoldStyle;
        case 'body-16-bold':
            return Body16BoldStyle;
        case 'body-16-semibold-underline':
            return Body16SemiBoldUnderlineStyle;
        case 'body-16-regular-underline':
            return Body16RegularUnderlineStyle;

        case 'caption-12-regular':
            return Caption12RegularStyle;
        case 'caption-12-medium':
            return Caption12MediumStyle;
        case 'caption-12-semi-bold':
            return Caption12SemiBoldStyle;
        case 'caption-12-bold':
            return Caption12BoldStyle;
        case 'caption-12-semibold-underline':
            return Caption12SemiBoldUnderlineStyle;
        case 'caption-12-regular-underline':
            return Caption12RegularUnderlineStyle;

        case 'caption-10-regular':
            return Caption10RegularStyle;
        case 'caption-10-medium':
            return Caption10MediumStyle;
        case 'caption-10-semi-bold':
            return Caption10SemiBoldStyle;
        case 'caption-10-bold':
            return Caption10BoldStyle;
        case 'caption-10-semibold-underline':
            return Caption10SemiBoldUnderlineStyle;
        case 'caption-10-regular-underline':
            return Caption10RegularUnderlineStyle;

        //old title
        case 'title-h1-42-bold':
            return TitleH142BoldStyle;
        case 'title-h2-32-bold':
            return TitleH232BoldStyle;
        case 'title-h2-64-medium':
            return TitleH264BoldStyle;
        case 'title-h1-72-bold':
            return TitleH172BoldStyle;

        //old components

        case 'subtitle-20-bold':
            return Subtitle20BoldStyle;

        case 'subtitle-20-medium':
            return Subtitle20MediumStyle;

        case 'title-h3-24-bold':
            return TitleH324BoldStyle;

        default:
            throw new Error('invalid argument');
    }
};
