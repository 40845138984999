import React, {useCallback, useMemo, useState} from 'react';
import {toast} from "react-toastify";
import {submitCampaignFormData} from "../../../../apiManager/practiceFrontsApis/practiceFront";
import {styled} from "../../../../styles/stitches.config";
import {BlockTypeProps} from '../../../../typings/interfaces/block-type-props';
import { getInnerStyleConfiguration } from '../../../../utils/styleConfiguration';
import { css } from '@stitches/react';

const inputStyles = css({
    '&::placeholder': {
        color: 'white', // change this to your desired color
    },
});

const Grid = styled('form', {
    height: 'auto',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    padding: '9px 24px',
    alignItem: 'center',
    columnGap: '16px',
    border: '1.46px solid #D7DFDC',
    borderRadius: '21.88px',
    '@md': {
        padding: '16px 24px',
    },
    '@lg': {
        maxWidth: '90%',
    }

});
const ButtonAnimation = styled('button', {
    width: '44px',
    display: 'flex',
    height: '40px',
    alignItem: 'center',
    justifyContent: 'center',
    borderRadius: '100%',
    backgroundColor: '#886073',
    border: 'none',
    outline: "none",
});
const InputBox = styled('input', {
    width: '100%',
    textAlign: 'left',
    background: 'transparent',
    fontSize: '18px',
    lineHeight: '27px',
    color: '#F3F2ED',
    border: 'none',
    outline: "none",
});

const InputWithButton: React.FC<BlockTypeProps> = ({blockNode}) => {
    const [email, setEmail] = useState<string>('');
    let dynamicStyles: any = {};
    if (blockNode.design.styles) {
        getInnerStyleConfiguration(blockNode, dynamicStyles);
    }

    const placeholder = useMemo(() => {
        return blockNode?.data?.input?.value
    }, [blockNode?.data?.input?.value]);
    const buttonText = blockNode.data || '';
    const dynamicClasses = [...(blockNode?.metadata?.classes || [])];
    const buttonCss = blockNode?.metadata?.buttonCss || {backgroundColor: '#886073'}
    const iconColor = blockNode?.metadata.iconColor || "#F3F2E7";

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setEmail(value);
    };

    const onSubmit = (event: any) => {
        event.preventDefault();
    };
    const getFilteredClasses = useCallback((layoutCls: any) => {
        const layout =  layoutCls;
        return layout.join(' ');
    }, [])

    const handleClickAction = () => {
        const campaignId = buttonText?.buttonAction?.value;

        if(campaignId && email) {
                const body: any = {}

                if (email) {
                    body.email = email
                }
                if(email) {
                    submitCampaignFormData(body, campaignId).then(r => {
                     setEmail('')
                        toast.success('Thank you for subscription', {
                            position: 'top-center',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: 'light',
                            className: 'text-mauve ',
                        });

                    }).catch((er) => {
                        toast.error('Sorry,Subscription request failed', {
                            position: 'top-center',
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: 'light',
                            className: 'text-mauve ',
                        });
                    })
                }
                else toast.error('Please fill up email address', {
                    position: 'top-center',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                    className: 'text-mauve ',
                });
            }
        else toast.error('Please fill up the form data', {
                position: 'top-center',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                className: 'text-mauve ',
            });
        }



    return (
        <Grid onSubmit={onSubmit}>
            <InputBox type='email' id={blockNode?._id} placeholder={placeholder}
                      className={getFilteredClasses(dynamicClasses)+inputStyles()}
                      onChange={handleOnChange}
                      css={{...dynamicStyles}}
                      value={email}
            />
            <ButtonAnimation style={{...buttonCss}}
                             onClick={handleClickAction}>
                <svg
                    width="10"
                    height="8"
                    viewBox="0 0 10 8"
                    fill="none"
                    className={'m-auto flex justify-center'}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M6.06226 0.0869952H4.04813L7.02476 3.08145L0.429824 3.06363V4.64998H7.02476L4.04813 7.64444H6.06226L9.84098 3.86572L6.06226 0.0869952Z"
                        fill={iconColor}
                    />
                </svg>
            </ButtonAnimation>
        </Grid>

    )
}


export default InputWithButton;