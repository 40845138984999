import React from "react";
import {
  PracticeFrontNode,
  PracticeFrontRoot,
  PracticeFrontSection,
} from "../../../../typings/interfaces/practice-front-node.interface";
import ColorThemeRenderer from "../ColorThemeRenderer";
import NodeRenderer from "../NodeRenderer";

const RootRenderer: React.FC<{ rootNode: PracticeFrontRoot }> = ({
  rootNode,
}) => {
  let headerHeight: string;

  if (rootNode?.children) {
    const headerSection = rootNode.children.find(
      (section) => section.generalSettings.type === "Header"
    ) as PracticeFrontSection;
    if (headerSection) {
      headerHeight = headerSection.metadata?.headerHeight;
      const headerStyles = {
        top: 0,
        left: 0,
        right: 0,
        overflow: "hidden",
        position: "fixed",
        zIndex: 1,
      };
      if (headerSection.design.styles) {
        headerSection.design.styles = {
          ...headerSection.design.styles,
          ...headerStyles,
        };
      }
      const headerTitles: any[] = [];
      const headerMenus = rootNode.children
        .filter((item: PracticeFrontSection) => item.showInSiteHeader)
        .map((item: PracticeFrontSection) => {
          return {
            sectionId: item._id,
            title: item?.generalSettings?.title || "",
            order: item.generalSettings.order,
          };
        })
        .filter((item) => {
          if (headerTitles.includes(item.title)) {
            return false;
          } else {
            headerTitles.push(item.title);
            return true;
          }
        });

      let headerBlock: any;

      const getHeaderMenuBlock = (node: PracticeFrontNode) => {
        if ((node as any).blockTypeId === "practice-front-header") {
          headerBlock = node;
        } else if (node.children?.length) {
          node.children.forEach((child) => {
            getHeaderMenuBlock(child);
          });
        }
      };
      getHeaderMenuBlock(headerSection);

      // let headerBlock = headerSection.children
      //     .map(item => item as PracticeFrontBlock)
      //     .find((block: PracticeFrontBlock) => block.blockTypeId === 'practice-front-header');
      if (headerBlock) {
        headerBlock.metadata = {};
        headerBlock.metadata.menus = headerMenus;
      }
      // else {
      //     throw "NOt found"
      // }
    }
  }

  return (
    <>
      <ColorThemeRenderer themeSettings={rootNode.themeSettings}>
        {rootNode?.children
          ?.sort(
            (a: any, b: any) =>
              a.generalSettings.order - b.generalSettings.order
          )
          .map((section: PracticeFrontNode, index: number) => {
            if (index === 1) {
              var clientHeight =
                document.getElementById("header")?.clientHeight;
              section.temp = {
                styles: {
                  marginTop: headerHeight || clientHeight || "120px",
                },
              };
            }
            return <NodeRenderer key={section._id} node={section} />;
          })}
      </ColorThemeRenderer>
    </>
  );
};

export default RootRenderer;
