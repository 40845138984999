import React from 'react';
import { BlockTypeProps } from '../../../../typings/interfaces/block-type-props';
import { styled} from "../../../../styles/stitches.config";
import { getInnerStyleConfiguration } from '../../../../utils/styleConfiguration';

const BaseButton = styled('div', {
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    outline: 'none',
    textDecoration: 'none',
    transition: 'all 100ms ease-in-out',
    '&:focus': {
        outline: 0,
    },
});

const PdfLink: React.FC<BlockTypeProps> = ({blockNode}) => {
    let dynamicStyles: any = {};
    if (blockNode.design.styles){
        getInnerStyleConfiguration(blockNode, dynamicStyles);
    }
    const link = blockNode.data?.link?.value || '';
    const linkText = blockNode.data?.linkText?.value || '';


    const handleClick = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>) => {
        window.open(link,'_blank')
    };
    return   (
        <BaseButton id={blockNode?._id}
                    as="button" onClick={handleClick}   css={{...dynamicStyles}}>
            {linkText ? linkText : ''}
        </BaseButton>
    )
}

export default PdfLink;