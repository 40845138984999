import React from 'react';
import { PracticeFrontSection} from "../../../../typings/interfaces-v1/practice-front-node.interface";
import LayoutRenderer from '../LayoutRenderer';
import NodeRenderer from "../NodeRenderer";

const  SectionRenderer:React.FC<{section: PracticeFrontSection}> =({section})=> {
    return (
        <>
        <LayoutRenderer node={section}>

            {
                section.generalSettings.isPublished && section.children.sort(((a:any,b:any)=> (a.generalSettings.order-b.generalSettings.order))).map((node)=>(
                <div key={node._id}>
                    <NodeRenderer key={node._id} node={node}  />
                </div>
                ))
            }
        </LayoutRenderer>

        </>
    );
}

export default SectionRenderer;
