import React from 'react';
import { BlockTypeProps } from '../../../../typings/interfaces/block-type-props';
import {styled} from "../../../../styles/stitches.config";

const SimpleTextDescription=styled('div',{
    whiteSpace: 'pre-wrap',
    textAlign:'center',
    wordBreak:'break-all',
    // overflow:'hidden',
    height:'auto',
    '@sm':{
        textAlign:'left'
    }
})

const SimpleDescription: React.FC<BlockTypeProps> = ({blockNode}) => {

    let styles: any = {};
    if (blockNode.design.styles){
        const temp = {...blockNode.design.styles}
        for (const key in temp){
            if (temp[key].value){
                styles[key] =temp[key].value
                delete temp[key];
            }
        }
        styles = {...styles, temp}
    }
    styles = {...styles,...blockNode?.animation?.styles}

    const text = blockNode.data.text.value;
    return (
        <SimpleTextDescription id={blockNode?._id}
                               as={blockNode?.metadata?.htmlTag || 'div'}
                              css={{styles}}
                             >
            {text}
        </SimpleTextDescription>

    )
}

export default SimpleDescription;