import React from 'react';
import { iconFor } from './networks';
import { socialSvgContent } from './styles';

function getStyle({ fgColor }: {fgColor: any}) {
    return {
        ...socialSvgContent,
        fill: fgColor || 'transparent',
    };
}

interface IconProps {
    fgColor?: string;
    networkKey: string;
}

const Icon: React.FC<IconProps> = ({ fgColor, networkKey, ...props }) => {
    return (
        <g {...props} className="social-svg-icon" style={getStyle({ fgColor })}>
            <path d={iconFor(networkKey) || ''} />
        </g>
    );
};

export default Icon;
