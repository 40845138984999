import React, {Fragment, useEffect, useState} from 'react';
import { Link } from 'react-scroll'
import ImageHolder from '../../../../components/Image/imageHolder';
import { BlockTypeProps } from '../../../../typings/interfaces-v1/block-type-props';
import { MenuItemsProps } from '../../../../typings/interfaces-v1/practice-front-node.interface';
import { styled } from '../../../../styles/stitches.config';
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import HamburgerMenu from "./hamburgerMenu";
import ThemeStore from "../../../../stores/theme-store";
import { EASE_COLORS } from '../../../../constants/colors';


const ThemeNav= styled('nav', {
    position: 'relative',
    overflow:'hidden',
    fontSize:'16px',
    // width:'1200px',
})

const ThemeNavBody= styled('div',{
    margin:'1.2rem .3rem',
    display:'flex',
    justifyContent:'space-between',
     alignItems:'center',
    '@lg':{
        margin:'1.2rem 2.5rem',
    }

})

const NavLinks = styled('div', {
       display: 'inline-flex',
});

const NavLinksA = styled('div', {
    cursor:'pointer',
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '16px',
    color:'$link',
    flexGrow: '0',
    textDecoration:'none',
    margin: '0px 1rem',
        '&:hover': {
            opacity:'.5'
        },
      '&:active':{
        color:'$primary',
      }
});
const SiteHeader: React.FC<BlockTypeProps> = ({blockNode}) => {
    const { theme} = ThemeStore( (state)=>({theme: state.theme}))

    let colorPalettes = theme?.themeSettings?.colorPalettes || {
        primary:  EASE_COLORS.PRIMARY_COLOR,
        secondary: EASE_COLORS.SECONDARY_COLOR,
        warn: EASE_COLORS.WARNING_COLOR,
        info:  EASE_COLORS.WHITE,
    }
    let fonts = theme?.themeSettings?.fonts || {
        title: 'Noto Serif',
        body: 'Work Sans',
        subtitle: 'Work Sans',
        paragraph: 'Noto Serif',
    };

    const {warn}= colorPalettes as any;
    const {body:bodyFont}= fonts as any;


    const logoUrl= blockNode.data.logo?.value;
    const [viewWidth,setViewWidth]=useState('lg');
    const { width } = useWindowDimensions();
    useEffect(()=>{
        if(width >=0 && width<=767) setViewWidth('sm')
        else setViewWidth('lg')
    },[width])
   // const {primary,secondary,danger,}
    return (
        <Fragment>
            <ThemeNav>
                <ThemeNavBody>
                       <ImageHolder properties={{
                           src: logoUrl,
                           alt: "Logo",
                           style:{ height: '30px'}
                       }}>
                       </ImageHolder>
                        {/*<NavTitle>*/}
                        {/*    Logo*/}
                        {/*</NavTitle>*/}


                    {
                        viewWidth !== "lg" ?

                    <HamburgerMenu blockNode={blockNode}/> :
                            <NavLinks>
                                {blockNode && blockNode.data && blockNode.data.metadata?.menus.map((menu: MenuItemsProps) => (
                                    <NavLinksA key={menu.sectionId} style={{color: warn, fontFamily:bodyFont}}>
                                        <Link activeClass="active"
                                              to={menu.title.toLocaleLowerCase()}
                                              spy={true}
                                              smooth={true}
                                            // hashSpy={true}
                                              offset={-90}
                                              duration={400}
                                              delay={200}
                                              isDynamic={true}
                                              ignoreCancelEvents={false}
                                        >{menu.title} </Link>
                                    </NavLinksA>
                                ))}
                            </NavLinks>
                    }
                </ThemeNavBody>
            </ThemeNav>
        </Fragment>
    )
}

export default SiteHeader;