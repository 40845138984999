import { styled, CSS } from '../../styles/stitches.config';
import React from 'react';
import Heading from './Heading';
import Text from './Text';
import { VariantMappingType, VariantType } from './types';
import { getTypographyStyle } from './Typography.style';

const Base = styled('div', {});

const defaultVariantMapping = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6',
    title: 'h2',
    subtitle: 'h6',
    body: 'span',
    caption: 'span',
    p: 'p',
    div: 'div',
};

interface Props {
    variant: VariantType;
    style?: CSS;
    as?: VariantMappingType;
}

const Typography: React.FC<Props> = ({ variant, style = {}, as, children }) => {
    // @ts-ignore
    const getVariantMapping = defaultVariantMapping[variant.split('-')[0]];
    return (
        <Base css={{ ...getTypographyStyle(variant), ...style, fontFamily: '$inter' }} as={as || getVariantMapping}>
            {children}
        </Base>
    );
};

export { Text, Heading };

export default Typography;
