import axios from "axios";
import { practiceFrontHttpClient } from "../practiceFrontRootHttpClient";
import { getWindowDimensions } from "../../utils/getWindowDimensions";
import { CampaignURL } from "../apisAccessPoint";

const { isMobile, isTablet } = getWindowDimensions();

export const getPracticeFrontByDomain = async (values: {
  page?: string;
  subdomain: string;
}): Promise<any> => {
  const param =
    values?.page && values?.page !== "/" ? `&path=/${values?.page}` : "";
  return practiceFrontHttpClient.then(({ get }) =>
    get(
      `/public/v1/practice-front/${values?.subdomain}?device=${
        isMobile ? "mobile" : isTablet ? "tablet" : "desktop"
      }${param}`
    )
  );
};
export const submitCampaignFormData = async (body:any, campaignId:string): Promise<any>=> {
    return practiceFrontHttpClient.then(({post}) => post(`${CampaignURL}/public/campaigns/${campaignId}/leads`, body));
};
