import React from 'react';
import { BlockTypeProps } from '../../../../typings/interfaces-v1/block-type-props';
import { styled } from '../../../../styles/stitches.config';
import ThemeStore from "../../../../stores/theme-store";
import { EASE_COLORS } from '../../../../constants/colors';

const SimpleOrderedListDiv=styled('div',{
    // margin:'2rem 0',
     '@md':{
        margin:".1rem 0"
     }
})
const Title=styled('h2',{
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '28px'
})
const Ol =styled('ol',{
    paddingLeft: '1rem',
});
const ListItem=styled('li',{
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '32px'
})

const SimpleOrderedList: React.FC<BlockTypeProps> = ({blockNode}) => {
    const { theme} = ThemeStore( (state)=>({theme: state.theme}))

    let colorPalettes = theme?.themeSettings?.colorPalettes || {
        primary:  EASE_COLORS.PRIMARY_COLOR,
        secondary: EASE_COLORS.SECONDARY_COLOR,
        warn: EASE_COLORS.WARNING_COLOR,
        info:  EASE_COLORS.WHITE,
    }
    let fonts = theme?.themeSettings?.fonts || {
        title: 'Noto Serif',
        body: 'Work Sans',
        subtitle: 'Work Sans',
        paragraph: 'Noto Serif',
    };

    const {info}= colorPalettes as any;
    const {body:bodyFont,}= fonts as any;

    const items = blockNode.data.listItems.value;
    return (
        <SimpleOrderedListDiv css={{...items.style}}>
            <Title style={{color: info, fontFamily:bodyFont}}>{blockNode?.data?.listTitle?.value}</Title>
            <Ol>
                {items.map((item: any, i: number)=><ListItem key={i} style={{color: info, fontFamily:bodyFont}}>{item}</ListItem>)}
            </Ol>
        </SimpleOrderedListDiv>
    )
}

export default SimpleOrderedList;