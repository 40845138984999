import React from 'react';
import { PracticeFrontSection} from "../../../../typings/interfaces/practice-front-node.interface";
import NodeRenderer from "../NodeRenderer";


const  SectionRenderer:React.FC<{section: PracticeFrontSection}> =({section})=> {
    const layoutClasses= [...(section?.layout?.classes || [])]
    const containerIndex = layoutClasses.indexOf('container');
    const containerFluidIndex =  layoutClasses.indexOf('container-fluid');
    let containerClasses = '';
    if (containerIndex >= 0){
        layoutClasses.splice(containerIndex,1);
        containerClasses += 'container';
    }
    if (containerFluidIndex >= 0){
        layoutClasses.splice(containerFluidIndex,1);
        containerClasses += 'container-fluid';
    }

    return (
        <>
        <div className={containerClasses} >
            <div className={layoutClasses.join(' ')}>

            {
                section.children?.sort(((a:any,b:any)=> (a.generalSettings.order-b.generalSettings.order))).map((node)=>(
                    <div key={node._id}  className={node?.layoutConfigs?.classes?.join(' ') || ''}>
                        <NodeRenderer key={node._id} node={node}  />
                    </div>
                ))
            }
            </div>
        </div>

        </>
    );
}

export default SectionRenderer;
