import React, {useEffect, useMemo, useState} from 'react';
import Typography from '../../../../components/Typography';
import useFormDataStore from "../../../../stores/formData";
import {styled} from "../../../../styles/stitches.config";
import {BlockTypeProps} from '../../../../typings/interfaces/block-type-props';
import {getInnerStyleConfiguration} from '../../../../utils/styleConfiguration';

const Grid = styled('div', {
    width: '100%',
    display: 'grid',
    alignItem: 'center',

});
const InputBox = styled('input', {
    width: '100%',
    textAlign: 'left',
    border: '1px solid #E5E5E5',
    padding: '14px 16px',
    borderRadius: '8px',
    background: 'white',
    '&:focus': {
        outline: 0,
    },
});
const TextAreaBox = styled('textarea', {
    width: '100%',
    textAlign: 'left',
    border: '1px solid #E5E5E5',
    padding: '14px 16px',
    borderRadius: '8px',
    background: 'white',
    '&:focus': {
        outline: 0,
    },
});

export const getComponentType = (label: string) => {
    let type;
    if (label === 'First Name') {
        type = 'firstName';
    }
    else if (label === 'Last Name') {
        type = 'lastName';
    }
    else if (label === 'Phone Number') {
        type = 'phone';
    }
    else if (label === 'Message') {
        type = 'message';
    }
    else type=label;
    return type;
}

const SimpleInput: React.FC<BlockTypeProps> = ({blockNode}) => {
    const componentType = useMemo(() => {
        return getComponentType(blockNode?.data?.input?.label) || 'firstName'
    }, [blockNode?.data?.input.label])

    const [inputValue, setInputValue] = useState('');
    const {formData, updateData} = useFormDataStore((store) => ({
        formData: store.formData,
        updateData: store.updateData
    }))
    const placeholder = useMemo(() => {
        return blockNode?.data?.input?.value
    }, [blockNode?.data?.input?.value]);

    const label = useMemo(() => {
        return blockNode?.data?.input?.label
    }, [blockNode?.data?.input?.label]);

    useEffect(() => {
        if (!formData) {
            setInputValue('')
        }
        // else {
        //     const getInputField= formData.find((item)=> item.parentId===blockNode._id  && item.type===componentType)
        //     if(!getInputField){
        //         setInputValue('')
        //     }
        // }
    }, [formData])

    let dynamicStyles: any = {};
    if (blockNode.design.styles) {
        getInnerStyleConfiguration(blockNode, dynamicStyles);
    }

    const dynamicClasses = [...(blockNode?.metadata?.classes || [])];

    const onChange = (e: any) => {
        const value = e.target.value
        setInputValue(value)
    };
    const handleBlur = (e: any) => {
        e.preventDefault()
        if (blockNode.parent && componentType) {
            const data = {
                parentId: blockNode.parent,
                [componentType]: inputValue
            }
            updateData(data, componentType)
        }

    };
    const isRequired = useMemo(() => {
        return blockNode.metadata?.isRequired;
    }, [blockNode?.metadata?.isRequired]);
    const isTextArea = useMemo(() => {
        return blockNode.metadata?.isTextArea;
    }, [blockNode?.metadata?.isTextArea]);
    const textAreaRows = useMemo(() => {
        return blockNode.metadata?.textAreaRows;
    }, [blockNode?.metadata?.textAreaRows]);

    return (
        <Grid>
            <Typography variant={'body-14-regular'} style={{pb: '8px'}}><span
                className={dynamicClasses.join()}>{label}</span>  {isRequired ?
                <span style={{color: '#D93036'}}>*</span> : ''}</Typography>
            {isTextArea ?   <TextAreaBox rows={textAreaRows || 2} onBlur={handleBlur} id={blockNode?._id} placeholder={placeholder || ''}

                                      value={inputValue}
                                      onChange={onChange} css={{...dynamicStyles}}/>
                :<InputBox type={componentType==='phone' ? 'number' : 'text'}  onBlur={handleBlur} id={blockNode?._id} placeholder={placeholder || ''}

                      value={inputValue}
                      onChange={onChange} css={{...dynamicStyles}}/>}
        </Grid>

    )
}

export default SimpleInput;