import React from 'react';

import { colorFor, maskFor } from './networks';
import { socialSvgMask } from './styles';

// @ts-ignore
function getStyle({ bgColor, networkKey }) {
    return {
        ...socialSvgMask,
        fill: bgColor || colorFor(networkKey),
    };
}

interface MaskProps {
    networkKey: string;
    bgColor?: string;
}

const Mask: React.FC<MaskProps> = ({ bgColor, networkKey, ...rest }) => (
    <g {...rest} className="social-svg-mask" style={getStyle({ bgColor, networkKey })}>
        <path d={maskFor(networkKey) || ''} />
    </g>
);

export default Mask;
