import React from 'react';
import { BlockTypeProps } from '../../../../typings/interfaces/block-type-props';
import { styled } from '../../../../styles/stitches.config';
import ThemeStore from "../../../../stores/theme-store";
import { EASE_COLORS } from '../../../../constants/colors';

const SimpleOrderedListDiv=styled('div',{
})
const FlexWrapper=styled('div',{
     display:'grid',
     gridTemplateColumns:'1fr',
     padding:"0 1rem",
    '@md':{
        display:'grid',
        gridTemplateColumns:'1fr 1fr',
    }


})
const Flex=styled('div',{
    display:'flex',
    flexDirection:'column',
})

const Title=styled('h2',{
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '28px'
})
const ListItem=styled('li',{
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '26px',
    '@md':{
        fontSize: '18px',
        lineHeight: '32px',
    }
})

const ServiceSectionDetails: React.FC<BlockTypeProps> = ({blockNode}) => {
    const { theme} = ThemeStore( (state)=>({theme: state.theme}))


    let colorPalettes = theme?.themeSettings?.colorPalettes || {
        primary:  EASE_COLORS.PRIMARY_COLOR,
        secondary: EASE_COLORS.SECONDARY_COLOR,
        warn: EASE_COLORS.WARNING_COLOR,
        info:  EASE_COLORS.WHITE,
    }
    let fonts = theme?.themeSettings?.fonts || {
        title: 'Noto Serif',
        body: 'Work Sans',
        subtitle: 'Work Sans',
        paragraph: 'Noto Serif',
    };

    const { secondary}= colorPalettes as any;
    const {body:bodyFont}= fonts as any;

    const items = blockNode.data;
    return (
        <SimpleOrderedListDiv css={{...items.style}}>
           <FlexWrapper>
            <Flex>
            <Title style={{color: secondary, fontFamily:bodyFont}}>{items?.listTitle1?.value}</Title>
            <ol>
                {items?.listItems1?.value.map((item: any, i: number)=><ListItem key={i}>{item}</ListItem>)}
            </ol>
            </Flex>
               <Flex>
                   <Title style={{color: secondary, fontFamily:bodyFont}}>{items?.listTitle2?.value}</Title>
                   <ol>
                       {items?.listItems2?.value.map((item: any, i: number)=><ListItem key={i} style={{backgroundColor: secondary, fontFamily:bodyFont}}>{item}</ListItem>)}
                   </ol>
               </Flex>
           </FlexWrapper>
               <Flex css={{ padding:'0 1rem'}}>
                   <Title style={{color: secondary, fontFamily:bodyFont}}>{items?.listTitle3?.value}</Title>
                   <ol>
                       {items?.listItems3?.value.map((item: any, i: number)=><ListItem style={{backgroundColor: secondary, fontFamily:bodyFont}} key={i}>{item}</ListItem>)}
                   </ol>
               </Flex>

        </SimpleOrderedListDiv>
    )
}

export default ServiceSectionDetails;