interface GetWindowDimensionsProps{
    width: number;
    height: number;
    isMobile: boolean;
    isTablet: boolean;
    isDesktop: boolean;
}
export function getWindowDimensions(): GetWindowDimensionsProps {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
        isMobile: width <= 480,
        isTablet: width >=481 && width <= 1024,
        isDesktop: width >= 1025
    };
}