import React from 'react';
import { BlockTypeProps } from '../../../../typings/interfaces/block-type-props';
import placeholderImage from '../../../../assets/images/aboutMe.png';
import VideoPreview from "../../Components/VideoPreview/VideoPreview";

const SimpleVideo: React.FC<BlockTypeProps> = ({blockNode}) => {

    let dynamicStyles: any = {};
    if (blockNode.design.styles){
        const temp = JSON.parse(JSON.stringify(blockNode.design.styles))
        for (const key in temp){
            if (temp[key].type && temp[key].isInner){
                dynamicStyles[key] =temp[key].value
            } 
        }
    }

    const src= blockNode?.data?.image?.value.imageUrl || placeholderImage;
    const videoSrc= blockNode?.data?.video?.value || '';


    return   (
        <>

                <VideoPreview size={'LARGE'} videoUrl={videoSrc} url={src} iconColor={'white'} dynamicStyles={dynamicStyles}/>
         </>
    )
}

export default SimpleVideo;