import React, {Fragment, useEffect, useState} from 'react';
import { Link } from 'react-scroll'
import { BlockTypeProps } from '../../../../typings/interfaces/block-type-props';
import { MenuItemsProps } from '../../../../typings/interfaces/practice-front-node.interface';
import { styled } from '../../../../styles/stitches.config';
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import HamburgerMenu from "./hamburgerMenu";
import ThemeStore from "../../../../stores/theme-store";

const ThemeNav= styled('nav', {
})


const NavLinks = styled('div', {
       display: 'inline-flex',
});

const NavLinksA = styled('div', {
    cursor:'pointer',
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '16px',
    color:'$link',
    flexGrow: '0',
    textDecoration:'none',
        '&:hover': {
            opacity:'.5'
        },
      '&:active':{
        color:'$warning50',
      }
});
const SiteHeader: React.FC<BlockTypeProps> = ({blockNode}) => {
    const { theme} = ThemeStore( (state)=>({theme: state.theme}))

    let styles: any = {};
    if (blockNode.design.styles){
        const temp = {...blockNode.design.styles}
        for (const key in temp){
            if (temp[key].value){
                styles[key] =temp[key].value
                delete temp[key];
            }
        }
        styles = {...styles, temp}
    }
    styles = {...styles,...blockNode?.animation?.styles}

    const [viewWidth,setViewWidth]=useState('lg');
    const { width } = useWindowDimensions();
    useEffect(()=>{
        if(width >=0 && width<=767) setViewWidth('sm')
        else setViewWidth('lg')
    },[width])

    return (
        <Fragment>
            <ThemeNav id={blockNode?._id}>
                {/*<ThemeNavBody>*/}
                    {
                        viewWidth !== "lg" ?
                    <HamburgerMenu blockNode={blockNode} style={styles}/> :
                            <NavLinks>
                                {blockNode && blockNode.metadata?.menus.sort((a:any,b:any)=> a.order-b.order).map((menu: MenuItemsProps) => (
                                    <NavLinksA key={menu.sectionId}  css={{...styles}}>
                                        <Link activeClass="active"
                                              to={menu.title.toLocaleLowerCase()}
                                              spy={true}
                                              smooth={true}
                                              offset={-100}
                                              duration={400}
                                              delay={200}
                                              isDynamic={true}
                                              ignoreCancelEvents={false}
                                        >{menu.title} </Link>
                                    </NavLinksA>
                                ))}
                            </NavLinks>
                    }
                {/*</ThemeNavBody>*/}
            </ThemeNav>
        </Fragment>
    )
}

export default SiteHeader;