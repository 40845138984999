import React from 'react';
import {styled} from "../../../../styles/stitches.config";
import {BlockTypeProps} from "../../../../typings/interfaces/block-type-props";
import ThemeStore from "../../../../stores/theme-store";
import { EASE_COLORS } from '../../../../constants/colors';

const Container = styled('div', {
    textAlign: 'center',
});
const SubTitle=styled('div',{
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '14px',
    letter:'2px',
    textAlign:'center',
    opacity: 0.7,
    mx:'auto',
    // maxWidth:'70%',
    width:"85%",
    '@md':{
        width:'45%'
    },
    '@xl':{
        width:'35%'
    }
})
const Title =styled('div',{
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '36px',
    lineHeight: '44px',
    letterSpacing:'-0.5px',
    marginBottom: '2rem',
    textAlign:'center',
    wordBreaks:'break',


})
const PricingHeader: React.FC<BlockTypeProps> = ({blockNode}) => {
    const { theme} = ThemeStore( (state)=>({theme: state.theme}))

    let colorPalettes = theme?.themeSettings?.colorPalettes || {
        primary:  EASE_COLORS.PRIMARY_COLOR,
        secondary: EASE_COLORS.SECONDARY_COLOR,
        warn: EASE_COLORS.WARNING_COLOR,
        info:  EASE_COLORS.WHITE,
    }
    let fonts = theme?.themeSettings?.fonts || {
        title: 'Noto Serif',
        body: 'Work Sans',
        subtitle: 'Work Sans',
        paragraph: 'Noto Serif',
    };

    const {warn}= colorPalettes as any;
    const {body:bodyFont}= fonts as any;

    const {title, subtitle} = blockNode?.data;
    return(
        <Container>
            <Title style={{color: warn, fontFamily:bodyFont}}>{title?.value}</Title>
            <SubTitle style={{color: warn, fontFamily:bodyFont}}>{subtitle?.value}</SubTitle>
        </Container>
    );
}

export default PricingHeader;