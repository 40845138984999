import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { PracticeFrontSection } from "../../../../typings/interfaces/practice-front-node.interface";
import NodeRenderer from "../NodeRenderer";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import NextImage from "../../../../assets/images/icons/nextRed.svg";
import PrevImage from "../../../../assets/images/icons/prevRed.svg";

const CarouselRenderer: React.FC<{ section: PracticeFrontSection }> = ({
  section,
}) => {
  const [viewPort, setViewPort] = useState("Desktop");
  const { width } = useWindowDimensions();
  useEffect(() => {
    if (width >= 0 && width <= 768) setViewPort("Mobile");
    if (width >= 769 && width <= 1079) setViewPort("Tablet");
    else setViewPort("Desktop");
  }, [width]);
  const layoutClasses = [...(section?.layout?.classes || [])];
  const containerIndex = layoutClasses.indexOf("container");
  const containerFluidIndex = layoutClasses.indexOf("container-fluid");
  let containerClasses = "";
  if (containerIndex >= 0) {
    layoutClasses.splice(containerIndex, 1);
    containerClasses += "container";
  }
  if (containerFluidIndex >= 0) {
    layoutClasses.splice(containerFluidIndex, 1);
    containerClasses += "container-fluid";
  }

  const sliderRef = useRef<Slider>(null);
  const next = () => {
    sliderRef.current?.slickNext();
  };

  const previous = () => {
    sliderRef.current?.slickPrev();
  };
  let settings = {
    infinite: true,
    dots: false,
    speed: 500,
    slidesToShow:
      viewPort === "Desktop"
        ? section.children.length > 2
          ? 3
          : section.children.length
        : viewPort === "Tablet"
        ? section.children.length > 1
          ? 2
          : 1
        : 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    nextArrow: <div />,
    prevArrow: <div />,
    responsive: [
      {
        breakpoint: 1080,
        settings: {
          slidesToShow:
            section.children.length > 2 ? 3 : section.children.length,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1079,
        settings: {
          slidesToShow: section.children.length > 1 ? 2 : 1,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          initialSlide: 0,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  const prevImg = PrevImage;
  const nextImg = NextImage;

  if (section?.metadata?.carouselOptions) {
    settings = {
      ...settings,
      ...section?.metadata?.carouselOptions,
    };
  }

  const hideArrow = !!section.metadata?.hideArrow;
  return (
    <>
      <div className={containerClasses}>
        <div className={layoutClasses.join(" ")}>
          {!hideArrow && (
            <div
              className={
                "d-flex gap-2 align-items-baseline justify-content-end"
              }
              style={{ paddingBottom: "62px" }}
            >
              <button
                onClick={previous}
                style={{
                  background: "transparent",
                  border: "none",
                  outline: "none",
                  width: "42px",
                  height: "43px",
                }}
              >
                <img
                  src={prevImg}
                  alt={"prev img"}
                  className={"w-full h-full"}
                />
              </button>

              <button
                onClick={next}
                style={{
                  background: "transparent",
                  border: "none",
                  outline: "none",
                  width: "42px",
                  height: "43px",
                }}
              >
                <img
                  src={nextImg}
                  alt={"prev img"}
                  className={"w-full h-full"}
                />
              </button>
            </div>
          )}

          <Slider {...settings} ref={sliderRef}>
            {section.children
              ?.sort(
                (a: any, b: any) =>
                  a.generalSettings.order - b.generalSettings.order
              )
              .map((node) => (
                <div
                  key={node._id}
                  className={node?.layoutConfigs?.classes?.join(" ") || ""}
                >
                  <NodeRenderer key={node._id} node={node} />
                </div>
              ))}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default CarouselRenderer;
