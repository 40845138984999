import React from "react";
import App from "./App";
import ErrorBoundary from "./components/ErrorBoundary";
import ReactQueryProvider from "./helpers/ReactQueryProvier";
import { BrowserRouter } from "react-router-dom";

const Root: React.FC = () => {
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <ReactQueryProvider>
          <App />
        </ReactQueryProvider>
      </ErrorBoundary>
    </BrowserRouter>
  );
};

export default Root;
