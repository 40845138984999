import React from 'react';
import {useNavigate} from "react-router-dom";
import {BlockTypeProps} from '../../../../typings/interfaces/block-type-props';
import {styled} from "../../../../styles/stitches.config";
import {LazyLoadImage} from 'react-lazy-load-image-component';


const ImageContainer = styled(LazyLoadImage, {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    display: 'flex'
})
const SimpleImage: React.FC<BlockTypeProps> = ({blockNode}) => {
    let navigate = useNavigate();
    let dynamicStyles: any = {};
    if (blockNode.design.styles) {
        const temp = JSON.parse(JSON.stringify(blockNode.design.styles))
        for (const key in temp) {
            if (temp[key].type && temp[key].isInner) {
                dynamicStyles[key] = temp[key].value
            }
        }
    }

    const imageUrl = blockNode?.data?.image?.value?.imageUrl || blockNode?.data?.image?.value || '';
    const altText = blockNode.data?.altText?.value || "Placeholder image"
    const dynamicClasses = [...(blockNode?.metadata?.classes || [])];
    const isHomePageLogo = blockNode?.data?.image?.metadata?.isHomePageLogo || false;

    const handleClick = () => {
        if (isHomePageLogo) {
            navigate('/')
        }
        return ;
    }

    return (
        <>
            <ImageContainer id={blockNode?._id} css={{...dynamicStyles}} className={dynamicClasses.join(' ')}
                            alt={altText}
                            onClick={handleClick}
                // effect="blur"
                            src={imageUrl} // use normal <img> attributes as props
            />
        </>
    )
}

export default SimpleImage;