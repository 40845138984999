import axios from 'axios';
import { PracticeFrontAccessPoint } from './apisAccessPoint';

const createInstance = async () => {
    const instance = axios.create({
        baseURL: PracticeFrontAccessPoint,
    });
    instance.interceptors.response.use(
        (res) => {
            return res.data;
        },
        (err) => {
            return Promise.reject(err.response.data);
        },
    );
    return instance;
};



export const practiceFrontHttpClient = createInstance();
