import React from 'react';
import { BlockTypeProps } from '../../../../typings/interfaces/block-type-props';
import { styled } from '../../../../styles/stitches.config';
import { BlockTypeComponentMap } from '../BlockTypeComponentMap';


const SimpleList: React.FC<BlockTypeProps> = ({blockNode}) => {
    let dynamicStyles: any = {};
    if (blockNode.design.styles){
        const temp = JSON.parse(JSON.stringify(blockNode.design.styles))
        for (const key in temp){
            if (temp[key].type && temp[key].isInner){
                dynamicStyles[key] =temp[key].value
            }
        }
    }
    const list = blockNode.data.listItems;

    const ListItemBlockTypeComponent: React.FC<any> =  BlockTypeComponentMap[list.type] as any;
    const getListContainer = (tag: any) => {
        return styled(tag || 'ol', {
        });
    }
    const ListContainer = getListContainer(blockNode.metadata.htmlTag);


    return (
        <ListContainer>
            {
                list.value.map((item: any) => {
                    const blockTypeProps: BlockTypeProps = {
                        blockNode: {
                            ...blockNode,
                            data: {
                                text: {
                                    ...item,
                                    type: list.type
                                }
                            }
                        }
                    }
                    return (
                        <>
                        {
                            <ListItemBlockTypeComponent key={Math.random()} {...blockTypeProps}/>
                        }
                    </>
                )
                       
                    // if (ListItemBlockTypeComponent)
                    //     return (
                    //         <>
                    //         {
                    //             <ListItemBlockTypeComponent key={Math.random()} {...blockTypeProps}></ListItemBlockTypeComponent>
                    //         }
                    //     </>
                    // )
                    // else return JSON.stringify(blockTypeProps)
                })
            }
        </ListContainer>
    );
    // return (
    //     <>
    //         {items.type==="ordered-list" ? <SimpleOrderedListDiv as={blockNode?.data?.metadata?.htmlTag || 'ol'}>
    //             {items.value.sort((a:any,b:any)=> a.order-b.order).map((item: any, i: number)=>(
    //                 <ListItem as={'li'}   css={{...dynamicStyles}} key={i} > {item.name}</ListItem>
    //             )
    //             )}
    //     </SimpleOrderedListDiv> :''}
    //         {items.type==="unordered-list" ? <SimpleOrderedListDiv as={blockNode?.data?.metadata?.htmlTag || 'ul'}>
    //             {items.value.sort((a:any,b:any)=> a.order-b.order).map((item: any, i: number)=>(
    //                     <ListItem as={'li'}  css={{...dynamicStyles}} key={i} > {item.name}</ListItem>
    //                 )
    //             )}
    //         </SimpleOrderedListDiv> :''}
    //         {items.type==="custom-list" ? <SimpleOrderedListDiv as={ 'div'}>
    //             {items.value.sort((a:any,b:any)=> a.order-b.order).map((item: any, i: number)=>(
    //                     <ListItem as={'div'}   css={{...dynamicStyles}} key={i} > <Icon type={blockNode?.metadata?.iconType || 'sign'}/> {item.name}</ListItem>
    //                 )
    //             )}
    //         </SimpleOrderedListDiv> :''}
    //     </>
    // )
}

export default SimpleList;