import React, {Fragment} from 'react';
import {PracticeFrontBlock} from "../../../../typings/interfaces/practice-front-node.interface";
import {styled} from "../../../../styles/stitches.config";
import ThemeStore from "../../../../stores/theme-store";
import { EASE_COLORS } from '../../../../constants/colors';

const Box = styled('div',{
    margin:'1.5rem',
    '@lg':{
        margin:'2.5rem 2.5rem 5rem 2.5rem',
    }
})
const Content = styled('div',{
    paddingTop:'.1rem',
    '@lg':{
        paddingTop:'2rem',
    }

})
const Title=styled('h1',{
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '34px',
    letter:'-0.5px',
    '@md':{

        fontSize: '28px',
        lineHeight: '38px',
    },
    '@lg':{

        fontSize: '36px',
        lineHeight: '44px',


    }

})
const SubTitle=styled('h4',{
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing:'-0.5px',
    '@md':{
        fontSize: '16px',
        lineHeight: '28px',
    },
    '@lg':{
        fontSize: '16px',
        lineHeight: '28px',
    }

})

interface AboutMeProps{
    blockNode: PracticeFrontBlock;
}
const AboutMeDetails: React.FC<AboutMeProps> = ({blockNode}) =>{
    const { theme} = ThemeStore( (state)=>({theme: state.theme}))


    let colorPalettes = theme?.themeSettings?.colorPalettes || {
        primary:  EASE_COLORS.PRIMARY_COLOR,
        secondary: EASE_COLORS.SECONDARY_COLOR,
        warn: EASE_COLORS.WARNING_COLOR,
        info:  EASE_COLORS.WHITE,
    }
    let fonts = theme?.themeSettings?.fonts || {
        title: 'Noto Serif',
        body: 'Work Sans',
        subtitle: 'Work Sans',
        paragraph: 'Noto Serif',
    };

    const {primary,warn}= colorPalettes as any;
    const {title: titleFont, paragraph:paragraphFont}= fonts as any;

    const {title, paragraphs} = blockNode?.data;

    return <Fragment>
        <Box>
                <Content>
                    <Title style={{color: primary,fontFamily:titleFont}} >{title?.value || ''}</Title>

                    {
                        paragraphs.value.map((item: string,index:number) => {
                            return <SubTitle key={index}  style={{color: warn,fontFamily:paragraphFont}} > {item}</SubTitle>
                        })
                    }
                </Content>
        </Box>
    </Fragment>
}

export default AboutMeDetails;