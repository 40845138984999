import React from 'react';
import { BlockTypeProps } from '../../../../typings/interfaces/block-type-props';
import { styled } from '../../../../styles/stitches.config';


const getListType = (elmTag: string) => {
    return styled(elmTag as any,{
    });
}
const ListItem=styled('div',{
})
const ListIcon=styled('img',{
    width:'18px',
    height:'18px',
    marginRight:'16px'
})
const ListContainer=styled('div',{
    alignItems:'center',
})

const SimpleOrderedList: React.FC<BlockTypeProps> = ({blockNode}) => {
    const listItems = blockNode.data?.listItems?.value || [];
    const listIcon = blockNode?.data?.listIcon?.value|| '';
    const listIconStyle = blockNode?.data?.listIcon?.metadata?.iconStyle ? JSON.parse(JSON.stringify(blockNode?.data?.listIcon?.metadata?.iconStyle)) : {};

    let dynamicStyles: any = {};
    if (blockNode.design.styles){
        const temp = JSON.parse(JSON.stringify(blockNode.design.styles))
        for (const key in temp){
            if (temp[key].type && temp[key].isInner){
                dynamicStyles[key] =temp[key].value
            }
        }
    }
    const ListType = getListType(blockNode.metadata?.htmlTag || "ol");

    return (
            <ListType>
                {listItems && listItems.map((item: any, i: number)=> <ListContainer key={i} >
                    <ListItem  as={listIcon? 'div' : 'li'} css={{...dynamicStyles}}
                              style={{ listStyleType: listIcon  ?'circle' : 'none' }}>
                        {!listIcon?'' : <ListIcon src={listIcon} alt={'list icon'} css={{...listIconStyle}}/>}
                        <span>{item} </span>
                    </ListItem>
                </ListContainer>
                )}
            </ListType>

    )
}

export default SimpleOrderedList;