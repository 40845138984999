import React from 'react';

import { socialSvgContent } from './styles';

const Background: React.FC = ({ ...props }) => (
    <g {...props} className="social-svg-background" style={socialSvgContent}>
        <circle cx="32" cy="32" r="31" />
    </g>
);

export default Background;
