import React from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import {  PracticeFrontSection } from '../../../../typings/interfaces-v1/practice-front-node.interface';

const ResponsiveGridLayout = WidthProvider(Responsive);
const  LayoutRenderer:React.FC<{node:PracticeFrontSection, children: any}> =({node, children})=> {

    const layouts: {[breakpoint: string]: any[]} = { }

    node.children.forEach(child => {
        for (let breakpoint in child.layoutConfigs){
            const config = child.layoutConfigs[breakpoint];
            config.i = child._id;
            if (child.children?.length){
                const childConfigs  = child.children.map(node => {
                    return node.layoutConfigs && node.layoutConfigs[breakpoint]
                })
                .filter(cfg => cfg);
                let maxHeight = 0;
                childConfigs.forEach((cfg: any) => {
                    const childHeight = cfg.y + cfg.h;
                    if (childHeight > maxHeight){
                        maxHeight = childHeight;
                    }
                })
                config.h = maxHeight;
                // console.log(child.generalSettings.title, breakpoint, config)
            }
            if(layouts[breakpoint]){
                layouts[breakpoint].push(config);
            }else {
                layouts[breakpoint] = [config]
            }
        }
    })

    if (node.layoutConfigs && Object.keys(node.layoutConfigs).length){

    }

    return (
        <ResponsiveGridLayout className="layout" layouts={layouts}
            breakpoints={node.layout.config?.breakpoints || {lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
            cols={node.layout.config?.cols || {lg: 12, md: 12, sm: 12, xs: 12, xxs: 12}}
            margin={node.layout.config?.margin}
            isResizable={true}
            autoSize={true}
            useCSSTransforms={true}
        >
       {
           children
       }
      </ResponsiveGridLayout>
    );
}

export default LayoutRenderer;
