const Primary500 = '#A96EF5';
const Neutral800 = '#111A34';
const Neutral500 = '#60728A';
const Neutral400 = '#94A3BA';
const Cream = '#FDF2E7';
const White = '#FFFFFF';
const Black = '#000000';
const Primary50 = '#EBE2F8';
const Background = '#FCFBFF';

export const EASE_COLORS = {
    BACKGROUND: Background,
    WHITE: White,
    BLACK: Black,
    NEUTRAL_50: '#F9FAFE',
    NEUTRAL_100: '#F2F6FC',
    NEUTRAL_200: '#E3E8F0',
    NEUTRAL_300: '#CED8E0',
    NEUTRAL_400: Neutral400,
    NEUTRAL_500: Neutral500,
    NEUTRAL_600: '#3F4F66',
    NEUTRAL_700: '#26384D',
    NEUTRAL_800: Neutral800,
    NEUTRAL_900: '#060B1C',

    //EASE PURPLE IS PRIMARY
    PRIMARY_COLOR: '#8026F5', //primary 700 is main primary
    PRIMARY_50: Primary50,
    PRIMARY_300: '#DAC4F5',
    PRIMARY_500: Primary500,
    PRIMARY_900: '#36007C',

    //EASE NAVY IS SECONDARY
    SECONDARY_COLOR: '#442162', //secondary 700 main is main secondary
    SECONDARY_50: '#F2E8FC',
    SECONDARY_300: '#CEBBDF',
    SECONDARY_500: '#7E6396',
    SECONDARY_900: '#240341',

    SUCCESS_COLOR: '#59B97E', //success 500 main is main success
    SUCCESS_50: '#E6F6EF',
    SUCCESS_300: '#74E4B2',
    SUCCESS_700: '#357550',
    SUCCESS_900: '#1E482F',

    WARNING_COLOR: '#EDCD4A', //warning 300 main is main warning
    WARNING_50: '#FFF7D7',
    WARNING_500: '#F4A540',
    WARNING_700: '#DB7B01',
    WARNING_900: '#804801',

    ERROR_COLOR: '#D93036', //error 500 main is main warning
    ERROR_50: '#FFEDEE',
    ERROR_300: '#ECA2A5',
    ERROR_700: '#971F17',
    ERROR_900: '#660600',

    CREAM_300: Cream,
    CREAM_700: Cream,

    ORANGI_300: '#FBD7B3',
    ORANGI_700: '#F69C42',

    FOAM_300: '#ABE8DF',
    FOAM_700: '#678B86',

    CONCRETE_300: '#E0E0E0',
    CONCRETE_700: '#B1B1B1',

    LINK: '#3870FF',
    BUTTON_HOVER: Primary500,
    ITEM_FOCUS: '#E8EBF1',
    ICON: '#6E6F89',
    DISABLE_BUTTON: '#CED8E0',

    TEXT_DEFAULT: Neutral800,
    TEXT_SUBDUED: Neutral500,
    TEXT_DISABLED: '#99A2B0',
    TEXT_MENU: '#41516E',
    TEXT_HELPER: Neutral400,
    TEXT_CREAM: Cream,
    TEXT_WHITE: White,

    BORDER_ONE: '#E5E5E5',
    BORDER_TWO: '#E0E7FF',
    BORDER_THREE: '#D9D9D9',
    NAVIGATION_BG: '#F5F6F8',
    INPUT_BG: '#F0F0F0',

    LITE_PRIMARY: Primary50,
    DASHBOARD_BG: '#FCFBFF',
    BLUE_JEANS: '#53B6FF',
    STEELBLUE: '#3A7FB3',
    LAVENDER_INDIGO: Primary500,
};
