import React, {Fragment} from 'react';
import {PracticeFrontBlock} from "../../../../typings/interfaces-v1/practice-front-node.interface";
import {styled} from "../../../../styles/stitches.config";
import placeHolder from '../../../../assets/images/aboutMe.png';

const Div = styled('div',{
    height:'auto',
    textAlign:'center',
    margin:'2rem',
    '@lg':{
        margin:'4rem 2.5rem 4rem 1.5rem',
    }
})

const Container= styled('div',{
    textAlign:'center',
    display:'flex'

})
const Banner= styled('img',{
    width:'100%',
    height:'350px',
    '@md':{
        width:'100%',
        height:'400px',
    },
    '@lg':{
        width:'100%',
        height:'500px',
    }

})

interface AboutMeProps{
    blockNode: PracticeFrontBlock;
}
const AboutMeBanner: React.FC<AboutMeProps> = ({blockNode}) =>{

    const {banner} = blockNode?.data;

    return <Fragment>
        <Div>
           <Container>
               <Banner
                src={ banner?.value || placeHolder}
                alt={ "Doctors image"}
                sizes="(max-width: 600px) 480px,800px"
            />
           </Container>

        </Div>
    </Fragment>
}

export default AboutMeBanner;