import React, { Component, ErrorInfo, ReactNode } from 'react';
import PageError from '../PageError';

interface Props {
    children: ReactNode;
}

interface State {
    name: string;
    message: string;
    hasError: boolean;
}

export default class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        name: '',
        message: 'Uncaught error',
        hasError: false,
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public static getDerivedStateFromError(err: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, message: err.message, name: err.name };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        console.error('Uncaught error:', error, errorInfo);
    }

    render(): React.ReactNode {
        if (this.state.hasError) {
            return <PageError title={this.state.message} />;
        }

        return this.props.children;
    }
}
