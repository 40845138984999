import React, {useState} from "react";
import {submitCampaignFormData} from "../../../../apiManager/practiceFrontsApis/practiceFront";
import useFormDataStore, {getStoreValue} from "../../../../stores/formData";
import {BlockTypeProps} from "../../../../typings/interfaces/block-type-props";
import {getInnerStyleConfiguration} from "../../../../utils/styleConfiguration";
import {BaseButton} from "../SimpleButton";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from "react-toastify";

const ActionButton: React.FC<BlockTypeProps> = ({blockNode}) => {
    let dynamicStyles: any = {};
    if (blockNode.design.styles) {
        getInnerStyleConfiguration(blockNode, dynamicStyles);
    }
    const buttonText = blockNode.data || '';
    const dynamicClasses = [...(blockNode?.metadata?.classes || [])];
    const {formData, reset} = useFormDataStore((store) => ({
        formData: store.formData,
        reset: store.resetFormData
    }))
    const handleClickAction = () => {
        const campaignId = buttonText?.buttonAction?.value;

        if(campaignId) {
            if (formData?.length && blockNode?.parent) {
                const params = getStoreValue(formData, blockNode.parent);
                const {firstName, lastName, phone, email,message} = params;
                const body: any = {}
                if (firstName) {
                    body.first_name = firstName
                }
                if (lastName) {
                    body.last_name = lastName
                }
                if (email) {
                    body.email = email
                }
                if (phone) {
                    body.phone = phone
                }
                if (message) {
                    body.message =message
                }

                if(email) {
                    submitCampaignFormData(body, campaignId).then(r => {
                        reset()
                        toast.success('Thank you for subscription', {
                            position: 'top-center',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: 'light',
                            className: 'text-mauve ',
                        });

                    }).catch((er) => {
                        toast.error('Sorry,Subscription request failed', {
                            position: 'top-center',
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: 'light',
                            className: 'text-mauve ',
                        });
                    })
                }
               else toast.error('Please fill up email address', {
                    position: 'top-center',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                    className: 'text-mauve ',
                });
            } else toast.error('Please fill up the form data', {
                position: 'top-center',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                className: 'text-mauve ',
            });
        }

    }
    return <>
        <BaseButton id={blockNode?._id} className={dynamicClasses.join(' ')}
                    as="button" onClick={handleClickAction} css={{...dynamicStyles}}>
            {buttonText?.buttonText?.value || 'Submit'}
        </BaseButton>
    </>
}
export default ActionButton;
