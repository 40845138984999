import React from 'react'
import {styled} from "../../../../styles/stitches.config";
import {PracticeFrontBlock} from "../../../../typings/interfaces-v1/practice-front-node.interface";
import Typography from "../../../../components/Typography";
import SocialIcon from "../SocialIcon";
import ThemeStore from "../../../../stores/theme-store";
import { EASE_COLORS } from '../../../../constants/colors';


const Div = styled('div', {
    display: 'flex',
    flexDirection: 'column',
});
const DesignStyle = styled('div', {
    display: 'flex',
    flexDirection:'column-reverse',
    marginTop: '1rem',
    paddingTop:'2rem',
    textAlign:'center',
    '@md':{
        marginBottom: '1rem',
        display: 'flex',
        flexDirection:'row',
        justifyContent: 'space-between',

    }
});

const IconHolder = styled('div', {
    display:'flex',
    alignItems:'center',
    justifyContent: 'space-around',
    marginBottom:'.5rem',
    '@md':{
        marginBottom:0,
    }
});
const Link = styled('a', {
    margin: '0 0.5rem',
});

const Divider = styled('div',{
    borderBottom: '1px solid #1A1A1F',
    margin: '1rem 0',
});
interface HeroProps{
    blockNode: PracticeFrontBlock;
}
const FooterContent: React.FC<HeroProps> = ({blockNode}) => {

    const { theme} = ThemeStore( (state)=>({theme: state.theme}))


    let colorPalettes = theme?.themeSettings?.colorPalettes || {
        primary:  EASE_COLORS.PRIMARY_COLOR,
        secondary:  EASE_COLORS.SECONDARY_COLOR,
        warn: EASE_COLORS.WARNING_COLOR,
        info:  EASE_COLORS.WHITE
    }
    let fonts = theme?.themeSettings?.fonts || {
        title: 'Noto Serif',
        body: 'Work Sans',
        subtitle: 'Work Sans',
        paragraph: 'Noto Serif',
    };

    const {warn}= colorPalettes as any;
    const {body:bodyFont}= fonts as any;

    const {copyright, facebook, instagram, linkedin, twitter} = blockNode?.data;
    const  currentDate =  new Date().getFullYear();
    return(
        <Div>
            <Divider/>
            <DesignStyle>
                <Typography  variant="caption-12-regular" style={{color: warn, fontFamily:bodyFont}}>Copyright © {currentDate} | {copyright?.value || ''}. All rights reserved</Typography>

                <IconHolder>
                    <Link target="_blank" href={facebook.value}><SocialIcon  url={facebook.value}/></Link>
                    <Link target="_blank" href={instagram.value}><SocialIcon  url={instagram.value}/></Link>
                    <Link target="_blank" href={linkedin.value}><SocialIcon  url={linkedin.value}/></Link>
                    <Link target="_blank" href={twitter.value}><SocialIcon  url={twitter.value}/></Link>
                </IconHolder>


            </DesignStyle>
        </Div>
    );
}
export default FooterContent;