import { useEffect } from 'react';
import WebFont from 'webfontloader';

const useWebFontLoader = (fonts: string[]): void => {
    useEffect(() => {
        if (!fonts){
            return;
        }else if (fonts.length > 0 && fonts[0].length > 0) {
            WebFont.load({
                google: {
                    families: fonts,
                },
            });
        }
    }, [fonts]);
};

export default useWebFontLoader;
