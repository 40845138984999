import DB from './_networks-db';

const DEFAULT_KEY = 'sharethis';
export const KEYS = Object.keys(DB);
const KEYS_REGEX = new RegExp('(?:https?:\\/\\/(?:[a-z0-9]*.)?)?(' + KEYS.join('|') + ').*');
// @ts-ignore
export const iconFor = (key: string): string | null => (DB[key] ? DB[key].icon : null);

// @ts-ignore
export const maskFor = (key: string): string | null => (DB[key] ? DB[key].mask : null);
// @ts-ignore
export const colorFor = (key: string): string | null => (DB[key] ? DB[key].color : null);

export const keyFor = (url: string): string => {
    if (!url) {
        return DEFAULT_KEY;
    }

    const key = url.replace(KEYS_REGEX, '$1');
    return key === url ? DEFAULT_KEY : key;
};

export const keysFor = (urls: string): string[] => {
    if (!urls || !Array.isArray(urls) || urls.length === 0) {
        return [];
    }

    return urls.map(keyFor);
};
