import React from 'react';

interface Props{
    src?:string,
    style?:any,
    alt?:any,
}
const  ImageHolder:React.FC<{properties:Props}>=({properties})=>{

    return(
        <img src={properties.src} alt={properties.alt} style={properties?.style || {}} />
    )

}

export default ImageHolder;