import React from 'react';
import {BlockTypeProps} from "../../../../typings/interfaces/block-type-props";
import {styled} from "../../../../styles/stitches.config";
import ThemeStore from "../../../../stores/theme-store";
import { EASE_COLORS } from '../../../../constants/colors';

const SubTitle = styled('p',{
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '28px',
    letterSpacing:'-0.5px',
    '@md':{
        fontSize: '24px',
        lineHeight: '36px',
    }
});
const EmergencyContainer = styled('div', {
    verticalAlign:'middle',
    my:'auto',
    mx:'auto',
});
const Emergency: React.FC<BlockTypeProps> = ({blockNode}) => {
    const { theme} = ThemeStore( (state)=>({theme: state.theme}))


    let colorPalettes = theme?.themeSettings?.colorPalettes || {
        primary:  EASE_COLORS.PRIMARY_COLOR,
        secondary: EASE_COLORS.SECONDARY_COLOR,
        warn: EASE_COLORS.WARNING_COLOR,
        info:  EASE_COLORS.WHITE,
    }
    let fonts = theme?.themeSettings?.fonts || {
        title: 'Noto Serif',
        body: 'Work Sans',
        subtitle: 'Work Sans',
        paragraph: 'Noto Serif',
    };

    const { secondary,info}= colorPalettes as any;
    const {title: titleFont}= fonts as any;

    const {emergency,phone,text,message} = blockNode?.data;
    return (
        <EmergencyContainer>
            <SubTitle css={{color:info}} style={{fontFamily:titleFont}}>{emergency?.value} <span style={{ color:secondary}}> {phone?.value}</span> <span> {text?.value}</span> <span style={{ color:secondary}}>{message?.value}</span></SubTitle>
        </EmergencyContainer>
    );
}

export default Emergency;