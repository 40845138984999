import React from 'react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger
} from '../../../../components/Accordion/Accordion.style';
import {BlockTypeProps} from "../../../../typings/interfaces/block-type-props";
import ThemeStore from "../../../../stores/theme-store";
import { EASE_COLORS } from '../../../../constants/colors';

const FAQs: React.FC<BlockTypeProps> = ({blockNode}) => {
    const { theme} = ThemeStore( (state)=>({theme: state.theme}))


    let colorPalettes = theme?.themeSettings?.colorPalettes || {
        primary:  EASE_COLORS.PRIMARY_COLOR,
        secondary: EASE_COLORS.SECONDARY_COLOR,
        warn: EASE_COLORS.WARNING_COLOR,
        info:  EASE_COLORS.WHITE,
    }
    let fonts = theme?.themeSettings?.fonts || {
        title: 'Noto Serif',
        body: 'Work Sans',
        subtitle: 'Work Sans',
        paragraph: 'Noto Serif',
    };

    const {warn}= colorPalettes as any;
    const { body:bodyFont}= fonts as any;

    const faq  = blockNode?.data;
    return(
            <Accordion
                defaultValue=""
                collapsible={true} type="single">
                <AccordionItem value={faq.title.value} >
                    <AccordionTrigger style={{color: warn,fontFamily:bodyFont}}>{faq?.title.value}</AccordionTrigger>
                    <AccordionContent style={{color: warn, fontFamily:bodyFont}}>{faq?.description.value}</AccordionContent>
                </AccordionItem>
            </Accordion>
    );
}

export default FAQs;