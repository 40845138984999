import {values} from "lodash";
import React, {useMemo, useRef} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import NextImage from "../../../../assets/images/icons/nextRed.svg";
import PrevImage from "../../../../assets/images/icons/prevRed.svg";
import Divider from "../../../../components/Divider";

import useWebFontLoader from "../../../../hooks/useWebFontLoader";
import {styled} from "../../../../styles/stitches.config";
import {BlockTypeProps} from "../../../../typings/interfaces/block-type-props";



const Text = styled('div', {})
const Img = styled('img', {
    '&:hover': {
        cursor: 'pointer'
    }
})
const Div = styled('div', {
    mb: '36px',
    '@md': {
        mb: '51px'
    }
})


const ListContainer = styled('div', {
    px: '1rem',
    border: '1px solid #5A2242',
    backgroundColor: '#F3F2E7',
    padding: '19px  46px  19px 33px',
    minHeight: '600px',
    maxHeight: '700px',
    borderRadius: '18px',

})


const Carousel: React.FC<BlockTypeProps> = ({blockNode}) => {
    const sliderRef = useRef<Slider>(null);
    useWebFontLoader(values(['Caveat']));
    const next = () => {
        sliderRef.current?.slickNext();
    };

    const previous = () => {
        sliderRef.current?.slickPrev();
    };
    const listItems = blockNode?.metadata?.carouselOptions ? JSON.parse(JSON.stringify(blockNode?.metadata?.carouselOptions)) : undefined;

    let dynamicStyles: any = {};

    if (blockNode.design.styles) {
        const temp = JSON.parse(JSON.stringify(blockNode.design.styles))
        for (const key in temp) {
            if (temp[key].type && temp[key].isInner) {
                dynamicStyles[key] = temp[key].value
            }
        }
    }

    const settings = {
        infinite: true,
        dots: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false,
        className: 'gap-1',
        nextArrow: <div/>,
        prevArrow: <div/>,
        responsive: [
            {
                breakpoint: 1080,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,

                }
            },
            {
                breakpoint: 1079,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                    infinite: true,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                }
            }
        ]

    };
    const text = blockNode?.data?.sliderTitle?.value || '';

    const prevImg = blockNode?.data?.prevIcon?.value || PrevImage;
    const nextImg = blockNode?.data?.nextIcon?.value || NextImage;

    const metadataSetting = useMemo(() => {
        return blockNode?.data?.metadata?.sliderOptions?.length ? {...settings, ...blockNode?.data?.metadata.sliderOptions} : settings
    }, [blockNode?.data?.metadata?.sliderOptions, settings])

    return (
        <div>
            <Div className={'d-flex justify-content-between '}>
                <Text id={blockNode?._id}
                      as={blockNode?.metadata?.htmlTag || 'div'}
                      css={{
                          ...dynamicStyles,
                      }}
                > {text}
                </Text>
                <div className={'flex gap-2 align-items-baseline '}>
                    <button onClick={previous} style={{
                        background: 'transparent',
                        border: "none", outline: 'none',
                        width: '42px', height:
                            '43px'
                    }}>
                        <img src={prevImg} alt={"prev img"} className={'w-full h-full'}/>

                    </button>

                    <button onClick={next} style={{
                        background: 'transparent',
                        border: "none", outline: 'none',
                        width: '42px', height:
                            '43px'
                    }}>
                        <img src={nextImg} alt={"prev img"} className={'w-full h-full'}/>

                    </button>

                </div>
            </Div>
            <Slider {...metadataSetting} ref={sliderRef}>

                {listItems && listItems?.map((item: any, i: number) => <ListContainer key={i}
                    >

                        {!item.image ? '' :
                            <Img src={item.image}
                                 css={{mt: '$3', width: 'auto', maxWidth: '165px', height: '150px'}}
                                 alt={'list icon'}/>}
                        <Text css={{
                            paddingTop: '26px',
                            fontWeight: 700,
                            color: '#5A2242',
                            fontSize: '32px',
                            '@lg': {
                                fontSize: '40px',
                            }
                        }} style={{
                            fontFamily:
                                'Caveat'
                        }}> {item.title}</Text>
                        <Divider css={{my: '25px', height: '1px', backgroundColor: '#5A2242'}}/>
                        <Text css={{
                            fontFamily: '$inter',
                            fontSize: '16px',
                            fontWeight: 400,
                            color: '#5A2242',
                            letterSpacing: '-2%'
                        }}> {item.description}</Text>

                        <Img src={NextImage} style={{marginTop: '32px', width: '43px', height: '43px'}}
                             alt={'next icon'}/>

                    </ListContainer>
                )}

            </Slider>

        </div>
    );


}


export default Carousel;