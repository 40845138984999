import React from 'react';
import {styled} from "../../styles/stitches.config";

interface TextProps {
    children: React.ReactNode;
}

const StyleText = styled('div', {
    fontWeight: 'normal',
    fontSize: '0.875rem',
    color: '$textDefault',
    overflowWrap: 'break-word',
    m: 0,
    p: 0,
    mb: '1em',
});

const Text: React.FC<TextProps> = ({ children }) => {
    return <StyleText>{children}</StyleText>;
};
export default Text;
