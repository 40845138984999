import React, {useCallback} from 'react';
import {PracticeFrontBlock} from "../../../../typings/interfaces-v1/practice-front-node.interface";
import {BlockTypeComponentMap} from "../../BlockTypes/BlockTypeComponentMap";
import useThemeStore from "../../../../stores/theme-store";
import {styled} from "../../../../styles/stitches.config";

const Div = styled('div', {
    variants: {
        state: {
            editable: {
                '&:hover':{
                    border: '1px dashed yellow',
                    boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                    cursor: 'pointer',
                }
            },
            view: {
                border: 0
            }

        },
    },
    border: '1px solid transparent'

});
const  BlockRenderer:React.FC<{block:PracticeFrontBlock}> =({block})=> {
    const Component: React.FC<any> =  BlockTypeComponentMap[block.blockTypeId] as any;

    return (
        <Div>
            {
                Component ? <Component  blockNode={block} /> : <h1>{block.blockTypeId}</h1>
            }
        </Div>
    );
}

export default BlockRenderer;
