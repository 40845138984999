import ReactPixel from "react-facebook-pixel";
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module'
import useThemeStore from "../../stores/theme-store";
import {useEffect} from "react";


const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
};

const PixelTracking = () => {


    const {pixelSettings} = useThemeStore();

    let tagManagerArgs = {
        gtmId: pixelSettings?.googleTagId || '',
    };


    useEffect(() => {
        if (pixelSettings && pixelSettings.googleTagId) {
            tagManagerArgs = {
                gtmId: pixelSettings?.googleTagId,
                dataLayer: {
                    path: window.location.pathname
                }
            }
        }
    }, [])

    TagManager.initialize(tagManagerArgs)


    ReactPixel.init(`${pixelSettings.metaPixelId}`, advancedMatching, options);
    ReactPixel.pageView(); // For tracking page view

    ReactGA.initialize(`${pixelSettings.googleAnalyticsId}`);
    ReactGA.pageview(window.location.pathname + window.location.search);


    return null;

}


export default PixelTracking;