import React, { useEffect, useMemo, useState } from "react";
import Typography from "../../../../components/Typography";
import useFormDataStore from "../../../../stores/formData";
import { styled } from "../../../../styles/stitches.config";
import { BlockTypeProps } from "../../../../typings/interfaces/block-type-props";
import { getInnerStyleConfiguration } from "../../../../utils/styleConfiguration";

const PRIMARY = "#E5E5E5";
const ERROR = "#F43636";
const Grid = styled("div", {
  width: "100%",
  display: "grid",
  alignItem: "center",
});
const InputBox = styled("input", {
  width: "100%",
  textAlign: "left",
  padding: "14px 16px",
  borderRadius: "8px",
  background: "white",
  "&:focus": {
    outline: 0,
  },
});

const SimpleEmail: React.FC<BlockTypeProps> = ({ blockNode }) => {
  const placeholder = useMemo(() => {
    return blockNode?.data?.input?.value;
  }, [blockNode?.data?.input?.value]);

  const label = useMemo(() => {
    return blockNode?.data?.input?.label;
  }, [blockNode?.data?.input?.label]);
  const dynamicClasses = [...(blockNode?.metadata?.classes || [])];
  const isRequired = useMemo(() => {
    return blockNode.metadata?.isRequired;
  }, [blockNode?.metadata?.isRequired]);
  const { formData, updateData } = useFormDataStore((store) => ({
    formData: store.formData,
    updateData: store.updateData,
  }));

  const [borderColor, setBorderColor] = useState<string>(PRIMARY);
  const [email, setEmail] = useState<{ value: string; error: boolean }>({
    value: "",
    error: false,
  });
  let dynamicStyles: any = {};
  if (blockNode.design.styles) {
    getInnerStyleConfiguration(blockNode, dynamicStyles);
  }

  useEffect(() => {
    if (formData === null) {
      setEmail({ value: "", error: false });
    }
    // else if(formData) {
    //     const getInputField= formData.find((item)=> item.blockTypeId ===blockNode._id && item.type==='email')
    //     console.log('execitn here',getInputField)
    //     if(!getInputField){
    //         alert('exe')
    //         setEmail({value: '', error: false})
    //     }
    // }
  }, [formData]);

  const emailValidation = () => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (email?.value?.length === 0) return true;
    else if (!email?.value || regex.test(email?.value) === false) {
      setEmail((pre) => ({
        error: true,
        value: pre.value,
      }));
      return false;
    }
    return true;
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail((pre) => ({
      value: value,
      error: pre.error,
    }));
  };
  const handleBlur = (e: any) => {
    e.preventDefault();
    if (emailValidation() && blockNode.parent) {
      setBorderColor(PRIMARY);
      const value = email?.value || "";
      const data = {
        parentId: blockNode.parent,
        email: value,
      };
      updateData(data, "email");
    } else setBorderColor(ERROR);
  };
  // console.log(formData,'all')

  return (
    <Grid>
      {label?.length > 0 && (
        <Typography variant={"body-14-regular"} style={{ pb: "8px" }}>
          <span className={dynamicClasses.join()}>{label}</span>{" "}
          {isRequired ? <span style={{ color: "#D93036" }}>*</span> : ""}
        </Typography>
      )}

      <InputBox
        value={email.value}
        id={blockNode?._id}
        placeholder={placeholder}
        onBlur={handleBlur}
        onChange={onChange}
        css={{ ...dynamicStyles }}
        style={{
          border: `1px solid ${dynamicStyles?.borderColor || borderColor}`,
        }}
      />
    </Grid>
  );
};

export default SimpleEmail;
