import React, {useMemo} from 'react';

import {styled} from "../../../../styles/stitches.config";
import {BlockTypeProps} from '../../../../typings/interfaces/block-type-props';
import {
    getInnerStyleConfigurationLgV2,
    getInnerStyleConfigurationMdV2,
    getInnerStyleConfigurationSmV2,
    getInnerStyleConfigurationV2
} from '../../../../utils/styleConfiguration';


const Text = styled('div', {})

const ResponsiveText: React.FC<BlockTypeProps> = ({blockNode}) => {

    const dynamicStyles = useMemo(() => {
        return blockNode?.design?.styles ? getInnerStyleConfigurationV2(blockNode.design.styles) : {};
    }, [blockNode?.design?.styles])

    const dynamicStylesLg = useMemo(() => {
        return blockNode?.design?.styles ? getInnerStyleConfigurationLgV2(blockNode.design.styles) : {};
    }, [blockNode?.design?.styles])

    const dynamicStylesMd = useMemo(() => {
        return blockNode?.design?.styles ? getInnerStyleConfigurationMdV2(blockNode.design.styles) : {};
    }, [blockNode?.design?.styles])


    const dynamicStylesSm = useMemo(() => {
        return blockNode?.design?.styles ? getInnerStyleConfigurationSmV2(blockNode.design.styles) : {};
    }, [blockNode?.design?.styles])


    const text = blockNode?.data?.text?.value || '';


    return (
        <Text id={blockNode?._id}
              as={blockNode?.metadata?.htmlTag || 'div'}
              css={{
                  ...dynamicStyles,
                  ...dynamicStylesSm,
                  '@md': {...dynamicStylesMd},
                  '@lg': {...dynamicStylesLg}
              }}
        > {text}
        </Text>

    )
}

export default ResponsiveText;