import type * as Stitches from '@stitches/react';
import { createStitches } from '@stitches/react';
import { EASE_COLORS } from '../constants/colors';


export const { styled, css, globalCss, keyframes, config } = createStitches({
    theme: {
        colors: {
            //primitives
            white: EASE_COLORS.WHITE,
            black: EASE_COLORS.BLACK,
            pureBlack: EASE_COLORS.BLACK,

            //Base colors
            primary: EASE_COLORS.PRIMARY_COLOR,
            primary50: EASE_COLORS.PRIMARY_50,
            primary300: EASE_COLORS.PRIMARY_300,
            primary500: EASE_COLORS.PRIMARY_500,
            primary900: EASE_COLORS.PRIMARY_900,

            secondary: EASE_COLORS.SECONDARY_COLOR,
            secondary50: EASE_COLORS.SECONDARY_50,
            secondary300: EASE_COLORS.SECONDARY_300,
            secondary500: EASE_COLORS.SECONDARY_500,
            secondary900: EASE_COLORS.SECONDARY_900,

            error: EASE_COLORS.ERROR_COLOR,
            error50: EASE_COLORS.ERROR_50,
            error300: EASE_COLORS.ERROR_300,
            error700: EASE_COLORS.ERROR_700,
            error900: EASE_COLORS.ERROR_900,

            warning: EASE_COLORS.WARNING_COLOR,
            warning50: EASE_COLORS.WARNING_50,
            warning500: EASE_COLORS.WARNING_500,
            warning700: EASE_COLORS.WARNING_700,
            warning900: EASE_COLORS.WARNING_900,

            success: EASE_COLORS.SUCCESS_COLOR,
            success50: EASE_COLORS.SUCCESS_50,
            success300: EASE_COLORS.SUCCESS_300,
            success700: EASE_COLORS.SUCCESS_700,
            success900: EASE_COLORS.SUCCESS_900,

            link: EASE_COLORS.LINK,
            blueJeans: EASE_COLORS.BLUE_JEANS,
            steelBlue: EASE_COLORS.STEELBLUE,
            lavenderIndigo: EASE_COLORS.LAVENDER_INDIGO,

            //Button colors
            buttonHover: EASE_COLORS.BUTTON_HOVER,
            disableButton: EASE_COLORS.DISABLE_BUTTON,

            //Text colors
            disableText: EASE_COLORS.TEXT_DISABLED,
            textDefault: EASE_COLORS.TEXT_DEFAULT,
            TextSubdued: EASE_COLORS.TEXT_SUBDUED,
            TextDisabled: EASE_COLORS.TEXT_DISABLED,
            MenuText: EASE_COLORS.TEXT_MENU,
            HelperText: EASE_COLORS.TEXT_HELPER,
            TextHelper: EASE_COLORS.TEXT_HELPER,
            textCream: EASE_COLORS.TEXT_CREAM,

            //Input colors
            focus: EASE_COLORS.ITEM_FOCUS,
            inputBg: EASE_COLORS.INPUT_BG,

            //Icon colors
            iconColor: EASE_COLORS.ICON,

            //Border colors
            border1: EASE_COLORS.BORDER_ONE,
            border2: EASE_COLORS.BORDER_TWO,
            border3: EASE_COLORS.BORDER_THREE,

            LitePrimary: EASE_COLORS.LITE_PRIMARY,
            purple200: EASE_COLORS.PRIMARY_300,
            primaryLite: EASE_COLORS.LITE_PRIMARY,

            //Background colors

            NavigationBg: EASE_COLORS.NAVIGATION_BG,
            background: EASE_COLORS.BACKGROUND,
            dashboardBg: EASE_COLORS.DASHBOARD_BG,

            //Orphan colors from old config
            border4: '#C4C4C4',
            gray: '#979797',
            info: '#41516E',
            InfoBox: '#ECF4FF',

            //new primitives colors from always creative
            neutral50: EASE_COLORS.NEUTRAL_50,
            neutral100: EASE_COLORS.NEUTRAL_100,
            neutral200: EASE_COLORS.NEUTRAL_200,
            neutral300: EASE_COLORS.NEUTRAL_300,
            neutral400: EASE_COLORS.NEUTRAL_400,
            neutral500: EASE_COLORS.NEUTRAL_500,
            neutral600: EASE_COLORS.NEUTRAL_600,
            neutral700: EASE_COLORS.NEUTRAL_700,
            neutral800: EASE_COLORS.NEUTRAL_800,
            neutral900: EASE_COLORS.NEUTRAL_900,

            orangi300: EASE_COLORS.ORANGI_300,
            orangi700: EASE_COLORS.ORANGI_700,

            concrete300: EASE_COLORS.CONCRETE_300,
            concrete700: EASE_COLORS.CONCRETE_700,
        },

        space: {
            0: '0px',
            1: '4px',
            2: '8px',
            3: '12px',
            4: '16px',
            5: '20x',
            6: '24px',
            7: '28px',
            8: '32px',
            9: '36px',
            10: '40px',
        },
        fonts: {
            untitled: 'Untitled Sans, -apple-system, system-ui, sans-serif',
            montserrat: 'Montserrat, sans-serif',
            inter: 'Inter, sans-serif',
            regolaPro: 'Regola Pro',
        },
        shadows: {
            primary: '#7F53FF',
            secondary: '#3619B0',
            gray: '#979797',
            info: '#41516E',
        },
    },
    utils: {
        p: (value: Stitches.PropertyValue<'padding'>) => ({
            paddingTop: value,
            paddingBottom: value,
            paddingLeft: value,
            paddingRight: value,
        }),
        pt: (value: Stitches.PropertyValue<'padding'>) => ({
            paddingTop: value,
        }),
        pr: (value: Stitches.PropertyValue<'padding'>) => ({
            paddingRight: value,
        }),
        pb: (value: Stitches.PropertyValue<'padding'>) => ({
            paddingBottom: value,
        }),
        pl: (value: Stitches.PropertyValue<'padding'>) => ({
            paddingLeft: value,
        }),
        px: (value: Stitches.PropertyValue<'padding'>) => ({
            paddingLeft: value,
            paddingRight: value,
        }),
        py: (value: Stitches.PropertyValue<'padding'>) => ({
            paddingTop: value,
            paddingBottom: value,
        }),

        m: (value: Stitches.PropertyValue<'margin'>) => ({
            marginTop: value,
            marginBottom: value,
            marginLeft: value,
            marginRight: value,
        }),
        mt: (value: Stitches.PropertyValue<'margin'>) => ({
            marginTop: value,
        }),
        mr: (value: Stitches.PropertyValue<'margin'>) => ({
            marginRight: value,
        }),
        mb: (value: Stitches.PropertyValue<'margin'>) => ({
            marginBottom: value,
        }),
        ml: (value: Stitches.PropertyValue<'margin'>) => ({
            marginLeft: value,
        }),
        mx: (value: Stitches.PropertyValue<'margin'>) => ({
            marginLeft: value,
            marginRight: value,
        }),
        my: (value: Stitches.PropertyValue<'margin'>) => ({
            marginTop: value,
            marginBottom: value,
        }),

        ta: (value: Stitches.PropertyValue<'textAlign'>) => ({ textAlign: value }),

        fd: (value: Stitches.PropertyValue<'flexDirection'>) => ({ flexDirection: value }),
        fw: (value: any) => ({ flexWrap: value }),

        ai: (value: any) => ({ alignItems: value }),
        ac: (value: any) => ({ alignContent: value }),
        jc: (value: any) => ({ justifyContent: value }),
        as: (value: any) => ({ alignSelf: value }),
        fg: (value: any) => ({ flexGrow: value }),
        fs: (value: any) => ({ flexShrink: value }),
        fb: (value: any) => ({ flexBasis: value }),

        bc: (value: Stitches.PropertyValue<'backgroundColor'>) => ({
            backgroundColor: value,
        }),

        br: (value: any) => ({
            borderRadius: value,
        }),
        btrr: (value: any) => ({
            borderTopRightRadius: value,
        }),
        bbrr: (value: any) => ({
            borderBottomRightRadius: value,
        }),
        bblr: (value: any) => ({
            borderBottomLeftRadius: value,
        }),
        btlr: (value: any) => ({
            borderTopLeftRadius: value,
        }),

        bs: (value: Stitches.PropertyValue<'boxShadow'>) => ({ boxShadow: value }),

        lh: (value: Stitches.PropertyValue<'lineHeight'>) => ({ lineHeight: value }),

        ox: (value: any) => ({ overflowX: value }),
        oy: (value: any) => ({ overflowY: value }),

        pe: (value: any) => ({ pointerEvents: value }),
        us: (value: any) => ({
            WebkitUserSelect: value,
            userSelect: value,
        }),
        size: (value: Stitches.PropertyValue<'height'>) => ({
            width: value,
            height: value,
        }),
    },
    media: {
        xxs: '(min-width:320px)',
        xs: '(min-width:480px)',
        sm: '(min-width:576px)',
        md: '(min-width: 768px)',
        lg: '(min-width: 992px)',
        xl: '(min-width: 1200px)',
        xxl: '(min-width: 1400px)',
    },
});

export const IconSpinnerKeyframes = keyframes({
    '0%': {
        transform: 'rotate(0deg)',
    },
    '100%': {
        transform: 'rotate(359deg)',
    },
});
export const fadeIn = keyframes({
    '0%': { opacity: '0' },
    '100%': { opacity: '1' },
});

export const IconSpinner = css({
    animation: `${IconSpinnerKeyframes} 2s infinite linear`,
});

export const LoadingSipnner = css({
    animation: `${IconSpinnerKeyframes} 0.8s infinite linear`,
});

export const globalStyles = globalCss({
    '*': {
        margin: 0,
        padding: 0,
        fontFamily: 'inherit',
        fontStyle: 'normal',
        outline: 0,
    },
});

export type CSS = Stitches.CSS<typeof config>;
