import React from 'react';
import { BlockTypeProps } from '../../../../typings/interfaces-v1/block-type-props';
import {styled} from "../../../../styles/stitches.config";
import ThemeStore from "../../../../stores/theme-store";
import { EASE_COLORS } from '../../../../constants/colors';

const ServiceSectionTitleDiv=styled('div',{
    padding:'1rem 1rem',
    marginTop:'2rem',
    '@md':{
        padding:'2rem 2.5rem',
        marginTop:'12rem',
    }
})
const Title=styled('div',{
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    letter:'2px',
})
const SubTitle=styled('div',{
    fontFamily: 'Noto Serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '24px',
    lineHeight: '34px',
    letterSpacing:'-0.5px',
    textAlign:'center',
    '@md':{
        fontFamily: 'Noto Serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '36px',
        lineHeight: '44px',
        textAlign:'left',
        letterSpacing:'-0.5px',
    }
})

const ServiceSectionTitle: React.FC<BlockTypeProps> = ({blockNode}) =>{
    const items= blockNode.data?.title;
    const styleElement= blockNode.design.styles;
    const { theme} = ThemeStore( (state)=>({theme: state.theme}))

    let colorPalettes = theme?.themeSettings?.colorPalettes || {
        primary:  EASE_COLORS.PRIMARY_COLOR,
        secondary: EASE_COLORS.SECONDARY_COLOR,
        warn: EASE_COLORS.WARNING_COLOR,
        info:  EASE_COLORS.WHITE,
    }
    let fonts = theme?.themeSettings?.fonts || {
        title: 'Noto Serif',
        body: 'Work Sans',
        subtitle: 'Work Sans',
        paragraph: 'Noto Serif',
    };

    const {info}= colorPalettes as any;
    const {title: titleFont, body:bodyFont,}= fonts as any;


    return <ServiceSectionTitleDiv style={{...styleElement}}>
            <Title style={{ color: info, fontFamily:bodyFont}}>{items?.value}</Title>
            <SubTitle style={{color: info, fontFamily:titleFont}}>{blockNode.data?.subtitle?.value}</SubTitle>
            <SubTitle style={{color: info, fontFamily:titleFont}}>{blockNode.data?.subtitle2?.value}</SubTitle>
    </ServiceSectionTitleDiv>
}

export default ServiceSectionTitle