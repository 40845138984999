import { HamburgerIcon } from '@easepractice/icons';
import React from 'react';
import { styled } from '../../../../styles/stitches.config';
import { BlockTypeProps } from '../../../../typings/interfaces/block-type-props';
import {getInnerStyleConfiguration} from "../../../../utils/styleConfiguration";

const IconButton = styled('button', {
    all: 'unset',
    fontFamily: 'inherit',
    borderRadius: '100%',
    height: 45,
    width: 45,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '$black',
    backgroundColor: '$white',
    '&:hover': { boxShadow: `0 2px 10px $black`, backgroundColor: '$background' , outline:"none", border:'none'},
    '&:focus': { outline:"none", border:'none'},
});

const SimpleHamBurger: React.FC<BlockTypeProps> = ({blockNode}) => {
    let dynamicStyles: any = {};
    if (blockNode.design.styles){
        getInnerStyleConfiguration(blockNode, dynamicStyles);
    }
    return (
        <IconButton 
            data-toggle="collapse" 
            data-target={`#navsection`} 
            aria-controls={'navsection'} 
            aria-expanded="false" 
            aria-label="Toggle navigation">
        <HamburgerIcon style={{ width:"25px", height:"25px"}}/>
    </IconButton>

    )
}

export default SimpleHamBurger;