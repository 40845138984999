import React from 'react'
import {Helmet} from "react-helmet";
import {PIXEL_CONFIG, SEOItems} from "../../typings/interfaces-v1/practice-front-node.interface";

const defaultTitle = 'Landing'
const defaultDescription = 'Beautiful website'
const defaultKeywords = 'Ease,website'
const defaultOGURL = ''
const defaultOGImage = '/public/ease_banner.svg'


const Head: React.FC<{ seoSettings: SEOItems, subdomain?: string, pixelSettings: PIXEL_CONFIG | null }> = ({
                                                                                                               seoSettings,
                                                                                                               subdomain,
                                                                                                               pixelSettings
                                                                                                           }) => (
    <Helmet>
        <meta charSet="utf-8"/>
        <title>{seoSettings?.siteTitle || subdomain}</title>
        <meta name="description" content={seoSettings?.siteDescription || "Best website in the world"}/>
        <meta name='keywords' content={seoSettings?.siteKeywords || defaultKeywords}/>

        <link rel='shortcut icon' href={seoSettings?.siteFavIcon || defaultOGImage}/>
        <link rel='icon' type='image/x-icon' href={seoSettings?.siteFavIcon || '/public/ease_banner.svg'}/>
        <link rel='apple-touch-icon' sizes='76x76' href={seoSettings?.siteFavIcon || ''}/>
        <link rel='icon' type='image/png' sizes='32x32' href={seoSettings?.siteFavIcon || ''}/>
        <link rel='icon' type='image/png' sizes='16x16' href={seoSettings?.siteFavIcon || ''}/>
        <link rel='mask-icon' href='/public/safari-pinned-tab.svg' color='#5bbad5'/>
        <meta name='msapplication-TileColor' content='#da532c'/>
        <meta name='theme-color' content='#ffffff'/>


        <meta property='og:url' content={seoSettings?.siteFavIcon || defaultOGURL}/>
        <meta property='og:title' content={seoSettings?.siteTitle || defaultTitle} key={seoSettings?.siteTitle}/>
        <meta property='og:description' content={seoSettings?.siteDescription || defaultDescription}/>
        <meta property='og:image' content={seoSettings?.siteFavIcon || defaultOGImage}/>
        <meta property='og:image:width' content='1200'/>
        <meta property='og:image:height' content='630'/>
        <meta name='twitter:title' content={seoSettings?.siteTitle || defaultTitle} key={seoSettings?.siteTitle}/>
        <meta name='twitter:description' content={seoSettings?.siteDescription || defaultDescription}/>
        <meta name='twitter:site' content={seoSettings?.siteFavIcon || defaultOGURL}/>
        <meta name='twitter:card' content='summary_large_image'/>
        <meta name='twitter:image'
              content={seoSettings?.siteFavIcon || defaultOGImage}/>



            {/*<script*/}
            {/*        dangerouslySetInnerHTML={{  __html: `!function (w, d, t) {*/}
            {/*        w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};*/}
            {/*        ttq.load('YOUR PIXEL ID WILL BE LOCATED HERE');*/}
            {/*        ttq.page();*/}
            {/*}(window, document, 'ttq')`}}*/}
           {/*/>*/}


        {/*{pixelSettings && pixelSettings.googleTagId && pixelSettings.googleTagId.length > 0 && <script*/}
        {/*    dangerouslySetInnerHTML={{*/}
        {/*        __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':*/}
        {/*        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],*/}
        {/*        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=*/}
        {/*        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);*/}
        {/*})(window,document,'script','dataLayer','${pixelSettings.googleTagId}')`*/}
        {/*    }}*/}
        {/*/>}*/}

        {/*{pixelSettings && pixelSettings.googleTagId && pixelSettings.googleTagId.length > 0 && <noscript>*/}
        {/*    <iframe src={`https://www.googletagmanager.com/ns.html?id=${pixelSettings.googleTagId}`}*/}
        {/*            height="0" width="0"*/}
        {/*            style="display:none;visibility:hidden"/>*/}

        {/*</noscript>}*/}


        {/*{pixelSettings && pixelSettings.googleAnalyticsId && pixelSettings.googleAnalyticsId.length > 0 &&*/}
        {/*    <script*/}
        {/*        async*/}
        {/*        src={`https://www.googletagmanager.com/gtag/js?id=${pixelSettings?.googleAnalyticsId}`}*/}
        {/*    />*/}
        {/*    /!*}*!/*/}
        {/*/!*{pixelSettings && pixelSettings.googleAnalyticsId && pixelSettings.googleAnalyticsId.length > 0 &&*!/*/}
        {/*    <script*/}
        {/*        dangerouslySetInnerHTML={{*/}
        {/*            __html: `*/}
        {/*    window.dataLayer = window.dataLayer || [];*/}
        {/*    function gtag(){dataLayer.push(arguments);}*/}
        {/*    gtag('js', new Date());*/}
        {/*    gtag('config', '${pixelSettings?.googleAnalyticsId}', {*/}
        {/*      page_path: window.location.pathname,*/}
        {/*    });*/}
        {/*  `,*/}
        {/*        }}*/}


        {/*    />*/}
            {/*}*/}


        {/*      {pixelSettings && pixelSettings.metaPixelId && pixelSettings.metaPixelId.length > 0 && <script*/}
        {/*          dangerouslySetInnerHTML={{*/}
        {/*              __html: `*/}
        {/* !function(f,b,e,v,n,t,s)*/}
        {/*          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?*/}
        {/*                  n.callMethod.apply(n,arguments):n.queue.push(arguments)};*/}
        {/*                  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';*/}
        {/*                  n.queue=[];t=b.createElement(e);t.async=!0;*/}
        {/*                  t.src=v;s=b.getElementsByTagName(e)[0];*/}
        {/*                  s.parentNode.insertBefore(t,s)}(window, document,'script',*/}
        {/*                  'https://connect.facebook.net/en_US/fbevents.js');*/}
        {/*                  fbq('init', '${pixelSettings.metaPixelId}');*/}
        {/*                  fbq('track', 'PageView');*/}
        {/*`*/}
        {/*          }}*/}
        {/*      />}*/}

        {/*      {pixelSettings && pixelSettings.metaPixelId && pixelSettings.metaPixelId.length > 0 &&*/}
        {/*          <noscript>*/}
        {/*              <img height="1"*/}
        {/*                   width="1"*/}
        {/*                   style="display:none"*/}
        {/*                   src={`https://www.facebook.com/tr?id=${pixelSettings.metaPixelId}&ev=PageView&noscript=1`}*/}
        {/*              /></noscript>}*/}


    </Helmet>
)
export default Head
