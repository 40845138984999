import React from 'react';
import {PracticeFrontBlock, PracticeFrontNode, PracticeFrontRoot, PracticeFrontSection} from "../../../../typings/interfaces-v1/practice-front-node.interface";
import ColorThemeRenderer from "../ColorThemeRenderer";
import NodeRenderer from "../NodeRenderer";


// <root>
//     <color-themeApis>
//         <section></section>
//         <section></section>
//         <section></section>
//     </color-themeApis>
// </root>

const  RootRenderer:React.FC<{rootNode:PracticeFrontRoot}> =({rootNode})=> {
    if(rootNode?.children){
        const headerSection = rootNode.children.find(section => section.generalSettings.title === 'Header') as PracticeFrontSection;
        if(headerSection){
            const headerMenus = rootNode.children.filter((item: PracticeFrontSection) => item.showInSiteHeader).map((item: PracticeFrontSection) => {
                return {
                    sectionId: item._id,
                    title: item?.generalSettings?.title || '',
                    order: item.generalSettings.order,

                }
            })
            let headerBlock = headerSection.children
                .map(item => item as PracticeFrontBlock)
                .find((block: PracticeFrontBlock) => block.blockTypeId === 'practice-front-header');
            if (headerBlock?.data) {
                headerBlock.data.metadata = {};
                headerBlock.data.metadata.menus = headerMenus;
            }
        }
    }

    return (
        <>
            <ColorThemeRenderer themeSettings={rootNode.themeSettings} >


                {rootNode.children.sort(((a:any,b:any)=> (a.generalSettings.order-b.generalSettings.order)))
                .map((section:PracticeFrontNode)=>(
                    <NodeRenderer key={section._id} node={section}/>
                ))}

            </ColorThemeRenderer>

        </>
    );
}

export default RootRenderer;
