import React from 'react';

export const socialIcon: React.CSSProperties = {
    display: 'inline-block',
    width: '28px',
    height: '28px',
    position: 'relative',
    overflow: 'hidden',
    verticalAlign: 'middle',
};

export const socialContainer: React.CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
};

export const socialSvg: React.CSSProperties = {
    borderRadius: '50%',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    fillRule: 'evenodd',
};

export const socialSvgContent: React.CSSProperties = {
    msTransition: 'fill 170ms ease-in-out',
    OTransition: 'fill 170ms ease-in-out',
    MozTransition: 'fill 170ms ease-in-out',
    WebkitTransition: 'fill 170ms ease-in-out',
    transition: 'fill 170ms ease-in-out',
    fill: 'transparent',
};

export const socialSvgMask: React.CSSProperties = {
    ...socialSvgContent,
    fill: '#0f0b0b',
};
