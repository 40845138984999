import * as Separator from '@radix-ui/react-separator';
import { styled } from '../../styles/stitches.config';


const Divider = styled(Separator.Root, {
    backgroundColor: '#E5E5E5',
    height: 2,
    width: '100%',
    my: '2em',
});

export default Divider;


