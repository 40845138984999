import React from "react";
import { Link } from "react-router-dom";
import { styled } from "../../../../styles/stitches.config";
import { BlockTypeProps } from "../../../../typings/interfaces/block-type-props";
import { getInnerStyleConfiguration } from "../../../../utils/styleConfiguration";

export enum CustomButtonTypes {
  "open-page" = "open-page",
  "scroll-to" = "scroll-to",
  "external" = "external",
}
const CustomNavLink = styled(Link, {
  boxSizing: "border-box",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  whiteSpace: "nowrap",
  verticalAlign: "middle",
  outline: "none",
  textDecoration: "none",
  transition: "all 100ms ease-in-out",
  "&:focus": {
    outline: 0,
  },
});
const BaseButton = styled("div", {
  boxSizing: "border-box",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  whiteSpace: "nowrap",
  verticalAlign: "middle",
  outline: "none",
  textDecoration: "none",
  transition: "all 100ms ease-in-out",
  "&:focus": {
    outline: 0,
  },
});
const SimpleCustomButton: React.FC<BlockTypeProps> = ({ blockNode }) => {
  let dynamicStyles: any = {};
  if (blockNode.design.styles) {
    getInnerStyleConfiguration(blockNode, dynamicStyles);
  }
  const { buttonText, buttonAction, buttonIcon } = blockNode.data || "";
  const dynamicClasses = [...(blockNode?.metadata?.classes || [])];
  const { type, data } = buttonAction.value;
  const handleClick = () => {
    if (type === CustomButtonTypes["external"]) {
      let url = data;
      if (!/^(http:|https:)/i.test(url)) url = "https://" + url;
      window.open(url);
    }
  };

  const getUrl = () => {
    if (type === CustomButtonTypes["open-page"]) {
      if (data?.page?.name === "Home") {
        return "/";
      } else return `${data?.page?.path || ""}`;
    } else if (type === CustomButtonTypes["scroll-to"]) {
      if (data?.page?.name === "Home") {
        return `/#${
          data?.section?.title.toLocaleLowerCase().replace(/\s/g, "") || ""
        }`;
      } else
        return `${data?.page?.path || ""}#${
          data?.section?.title.toLocaleLowerCase().replace(/\s/g, "") || ""
        }`;
    }
    return "/";
  };
  const buttonIconStyle = buttonIcon?.metadata?.styles || {};
  if (
    type === CustomButtonTypes["open-page"] ||
    type === CustomButtonTypes["scroll-to"]
  ) {
    return (
      <CustomNavLink
        to={getUrl()}
        replace
        id={blockNode?._id}
        className={dynamicClasses.join(" ")}
        css={{ ...dynamicStyles }}
      >
        {buttonIcon?.value && (
          <img
            style={{
              marginRight: "0.3rem",
              height: "15px",
              width: "15px",
              ...buttonIconStyle,
            }}
            src={buttonIcon?.value}
            alt="button Icon"
          />
        )}
        {buttonText ? buttonText?.value : ""}
      </CustomNavLink>
    );
  }
  return (
    <BaseButton
      id={blockNode?._id}
      className={dynamicClasses.join(" ")}
      as="button"
      onClick={handleClick}
      css={{ ...dynamicStyles }}
    >
      {buttonIcon?.value && (
        <img
          style={{
            marginRight: "0.3rem",
            height: "15px",
            width: "15px",
            ...buttonIconStyle,
          }}
          src={buttonIcon?.value}
          alt="button Icon"
        />
      )}
      {buttonText ? buttonText?.value : ""}
    </BaseButton>
  );
};

export default SimpleCustomButton;
