import React from 'react';
import {BlockTypeProps} from "../../../../typings/interfaces/block-type-props";
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger
} from '../../../../components/Accordion/Accordion.style';
import {createMarkup} from "../../../../utils/createMarkup";
import {getInnerStyleConfiguration} from "../../../../utils/styleConfiguration";
const SimpleFaq: React.FC<BlockTypeProps> = ({blockNode}) => {
    let dynamicStyles: any = {};
    if (blockNode.design.styles){
        getInnerStyleConfiguration(blockNode, dynamicStyles);
    }
    return(
        <Accordion css={{...dynamicStyles}} type="single" defaultValue="" collapsible>
            <AccordionItem  value="item-1">
                <AccordionTrigger style={{}}>{blockNode?.data?.question?.value}</AccordionTrigger>
                <AccordionContent style={{}}>
                    <div dangerouslySetInnerHTML={createMarkup(blockNode?.data?.answer?.value)}/>
                </AccordionContent>
            </AccordionItem>
        </Accordion>
    );
};
export default SimpleFaq;