import React from 'react';
import { BlockTypeProps } from '../../../../typings/interfaces/block-type-props';
import { styled} from "../../../../styles/stitches.config";
import { getInnerStyleConfiguration } from '../../../../utils/styleConfiguration';

export  const BaseButton = styled('div', {
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    outline: 'none',
    textDecoration: 'none',
    transition: 'all 100ms ease-in-out',
    '&:focus': {
        outline: 0,
    },
});
interface Props extends BlockTypeProps {
    onClick?: () => void;
    actionButton?: boolean;
}
const SimpleButton: React.FC<Props> = ({blockNode,actionButton,onClick}) => {
    let dynamicStyles: any = {};
    if (blockNode.design.styles){
        getInnerStyleConfiguration(blockNode, dynamicStyles);
    }

    const {buttonText, buttonAction} = blockNode.data?.button?.value || '';
    const dynamicClasses= [...(blockNode?.metadata?.classes || [])];

    const handleClick = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>) => {
        if (actionButton) {
            if (onClick) onClick()
        } else {
            let url = buttonAction;
            if (!/^(http:|https:)/i.test(url)) url = "https://" + url;
            window.open(url);
        }
    };
    return   (
        <BaseButton id={blockNode?._id}  className={dynamicClasses.join(' ')}
                    as="button" onClick={handleClick}   css={{...dynamicStyles}}>
        {buttonText ? buttonText : ''}
         </BaseButton>
    )
}

export default SimpleButton;