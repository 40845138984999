import React from 'react';
import { violet, mauve, blackA } from '@radix-ui/colors';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import {MenuItemsProps, PracticeFrontBlock} from "../../../../typings/interfaces/practice-front-node.interface";
import {Link} from "react-scroll";
import ThemeStore from "../../../../stores/theme-store";
import { keyframes, styled ,CSS} from '../../../../styles/stitches.config';
import { HamburgerIcon } from '@easepractice/icons';

const slideUpAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateY(2px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideRightAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateX(-2px)' },
    '100%': { opacity: 1, transform: 'translateX(0)' },
});

const slideDownAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateY(-2px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideLeftAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateX(2px)' },
    '100%': { opacity: 1, transform: 'translateX(0)' },
});

const StyledContent = styled(DropdownMenuPrimitive.Content, {
    width: '95vw',
    minWidth:'300px',
    backgroundColor: 'white',
    borderRadius: 6,
    padding: 5,

    marginLeft:'1.5rem',
    boxShadow:
        '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
    '@media (prefers-reduced-motion: no-preference)': {
        animationDuration: '400ms',
        animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
        willChange: 'transform, opacity',
        '&[data-state="open"]': {
            '&[data-side="top"]': { animationName: slideDownAndFade },
            '&[data-side="right"]': { animationName: slideLeftAndFade },
            '&[data-side="bottom"]': { animationName: slideUpAndFade },
            '&[data-side="left"]': { animationName: slideRightAndFade },
        },
    },
});
const Div  = styled('div', {
});


const itemStyles = {
    all: 'unset',
    fontSize: 13,
    lineHeight: 1,
    color: violet.violet11,
    borderRadius: 3,
    display: 'flex',
    alignItems: 'center',
    height: 25,
    padding: '0 5px',
    position: 'relative',
    paddingLeft: 25,
    userSelect: 'none',

    '&[data-disabled]': {
        color: mauve.mauve8,
        pointerEvents: 'none',
    },

    '&:focus': {
        backgroundColor: violet.violet9,
        color: violet.violet1,
    },
};

const StyledRadioItem = styled(DropdownMenuPrimitive.RadioItem, { ...itemStyles });


const StyledDropDownItem = styled(DropdownMenuPrimitive.Item, {
    padding:'.6rem .5rem',
});


const StyledSeparator = styled(DropdownMenuPrimitive.Separator, {
    height: 1,
    backgroundColor: violet.violet6,
    margin: 5,
});



// Exports
export const DropdownMenu = DropdownMenuPrimitive.Root;
export const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;
export const DropdownMenuContent = StyledContent;
export const DropdownMenuItem = StyledDropDownItem;
export const DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup;
export const DropdownMenuRadioItem = StyledRadioItem;
export const DropdownMenuSeparator = StyledSeparator;



const Box = styled('div', {});

const IconButton = styled('button', {
    all: 'unset',
    fontFamily: 'inherit',
    borderRadius: '100%',
    height: 45,
    width: 45,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: violet.violet11,
    backgroundColor: 'white',
    '&:hover': { boxShadow: `0 2px 10px ${blackA.blackA7}`, backgroundColor: violet.violet3 , outline:"none", border:'none'},
    '&:focus': { outline:"none", border:'none'},
});

interface PropsType{
    blockNode:PracticeFrontBlock,
    style:CSS,
}
export const HamburgerMenu:React.FC<PropsType> = ({blockNode,style}) => {

    return (
        <Box>
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <IconButton aria-label="Customise options" >
                        <HamburgerIcon style={{ width:"25px", height:"25px"}}/>
                    </IconButton>
                </DropdownMenuTrigger>

                <DropdownMenuContent sideOffset={-40}>
                    {blockNode && blockNode.metadata?.menus.sort((a:any,b:any)=> a.order-b.order).map((menu: MenuItemsProps) => (
                               <Div key={menu.sectionId} css={{...style}}>
                                   <Link activeClass="active"
                                         to={menu.title.toLocaleLowerCase()}
                                         spy={true}
                                         smooth={true}
                                         offset={-90}
                                         duration={100}
                                         delay={100}
                                         isDynamic={true}
                                         ignoreCancelEvents={false}
                                   >
                                   <DropdownMenuItem>
                                        {menu.title}
                                   </DropdownMenuItem>
                                    </Link>
                                       {/*</DropdownMenuTriggerItem>*/}
                        <DropdownMenuSeparator />
                        </Div>

                       ))}

                </DropdownMenuContent>
            </DropdownMenu>
        </Box>
    );
};

export default HamburgerMenu;

