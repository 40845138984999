import React from 'react';
import {styled} from "../../../../styles/stitches.config";
import {BlockTypeProps} from "../../../../typings/interfaces-v1/block-type-props";
import ThemeStore from "../../../../stores/theme-store";
import { EASE_COLORS } from '../../../../constants/colors';

const Container = styled('div', {
    textAlign: 'left',
    padding:'1.5rem 1rem',
    '@md':{
        padding:'1rem'
    }
});
const Title=styled('div',{
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '14px',
    letter:'2px',
    marginBottom: '2rem',

})
const SubTitle=styled('div',{
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '24px',
    lineHeight: '34px',
    letterSpacing:'-0.5px',
    textAlign:'center',
    '@md':{
        fontSize: '36px',
        lineHeight: '44px',
        textAlign:'left'
    }

})
const FaqHeader: React.FC<BlockTypeProps> = ({blockNode}) => {
    const { theme} = ThemeStore( (state)=>({theme: state.theme}))


    let colorPalettes = theme?.themeSettings?.colorPalettes || {
        primary:  EASE_COLORS.PRIMARY_COLOR,
        secondary:  EASE_COLORS.SECONDARY_COLOR,
        warn: EASE_COLORS.WARNING_COLOR,
        info:  EASE_COLORS.WHITE
    }
    let fonts = theme?.themeSettings?.fonts || {
        title: 'Noto Serif',
        body: 'Work Sans',
        subtitle: 'Work Sans',
        paragraph: 'Noto Serif',
    };
    const {primary,warn}= colorPalettes as any;
    const {title: titleFont, body:bodyFont}= fonts as any;

    const {title, subtitle} = blockNode?.data;
    return(
        <Container>
            <Title css={{color: warn}} style={{fontFamily:bodyFont}}>{title?.value}</Title>
            <SubTitle css={{color: primary}} style={{fontFamily:titleFont}}>{subtitle?.value}</SubTitle>
        </Container>
    );
}

export default FaqHeader;