import React from 'react'
import {BlockTypeProps} from "../../../../typings/interfaces/block-type-props";
import {styled} from "../../../../styles/stitches.config";
import ThemeStore from "../../../../stores/theme-store";
import { EASE_COLORS } from '../../../../constants/colors';


const Wrapper =styled('div',{
    padding:'1rem 0.1rem',
    '@md':{
        padding:'2rem 1.5rem',
    }
});

const Container =styled('div',{
    display: 'flex',
    flexDirection:'column-reverse',
    gap:'1rem',
    '@lg':{
        display: 'grid',
        gridTemplateColumns: '60% 40%',
        columnGap: '1rem',
    }

});
const Title=styled('div',{
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '14px',
    letter:'2px',
    marginBottom: '1rem',
    '@md':{
        marginBottom: '2rem',
    }
});
const SubTitle = styled('p',{
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '24px',
    lineHeight: '36px',
    letterSpacing:'-0.5px',
});
const SmallText = styled('div',{
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '28px',
    letterSpacing:'-0.5px',
});

const Info = styled('div',{

});
const Image = styled('img',{
    width: '100%',
    height:'300px',
    '@lg':{
        width:'445px',
        height:"480px",
}
});
const Divider = styled('div',{
    borderBottom: '1px solid $white',
    margin: '1rem 0',
});
const ContactContainer = styled('div', {
    paddingTop:'1rem',
    '@lg':{
        paddingTop:'2rem',
    }
});
const OfficeHoursContainer = styled('div', {


});

const ContactInfo: React.FC<BlockTypeProps> = ({blockNode}) => {
    const { theme} = ThemeStore( (state)=>({theme: state.theme}))

    let colorPalettes = theme?.themeSettings?.colorPalettes || {
        primary:  EASE_COLORS.PRIMARY_COLOR,
        secondary: EASE_COLORS.SECONDARY_COLOR,
        warn: EASE_COLORS.WARNING_COLOR,
        info:  EASE_COLORS.WHITE,
    }
    let fonts = theme?.themeSettings?.fonts || {
        title: 'Noto Serif',
        body: 'Work Sans',
        subtitle: 'Work Sans',
        paragraph: 'Noto Serif',
    };

    const {info}= colorPalettes as any;
    const {body:bodyFont}= fonts as any;


    const {title, locationImg, addressLine, phone, email, officeHours} = blockNode?.data;

    return(
        <Wrapper>
        <Container>
            <Info>
                <Title css={{color: info}} style={{fontFamily:bodyFont}} >{title?.value}</Title>

                <ContactContainer>
                    <SubTitle css={{color: info}} style={{fontFamily:bodyFont}}>{addressLine?.value}</SubTitle>
                    <SmallText css={{ color: info}} style={{fontFamily:bodyFont}}>{phone?.value}</SmallText>
                    <SmallText css={{color: info}} style={{fontFamily:bodyFont}}>{email?.value}</SmallText>
                </ContactContainer>
                <Divider/>
                <OfficeHoursContainer>
                    <SubTitle css={{color: info}} style={{fontFamily:bodyFont}}>{officeHours?.label}</SubTitle>
                    <div>
                        {
                            officeHours.value.map((hours: string, i: number)=><SmallText key={i} css={{color: info}} style={{fontFamily:bodyFont}}>{hours}</SmallText>)
                        }
                    </div>
                </OfficeHoursContainer>
                <Divider/>
            </Info>
            <Image src={locationImg?.value} alt={'Contact image'}/>
        </Container>
        </Wrapper>
    )
}

export default ContactInfo;