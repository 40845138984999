import { LoadingIcon } from '@easepractice/icons';
import React from 'react';
import { Link } from 'react-router-dom';
import {styled,IconSpinner,css } from "../../styles/stitches.config";

type ButtonHTMLTypes = 'submit' | 'reset' | 'button';
type ButtonTypes = 'default' | 'outlined' | 'text' | 'icon' | 'cancel';

export interface ButtonProps {
    type?: ButtonTypes;
    htmlType?: ButtonHTMLTypes;
    block?: boolean;
    onClick?: React.MouseEventHandler<HTMLElement>;
    disabled?: boolean;
    style?: React.CSSProperties;
    loading?: boolean;
    href?: string;
}

const Spinner = styled(LoadingIcon, {
    ...IconSpinner,
});

const BaseStyle = css({
    maxHeight: '2.25rem',
    boxSizing: 'border-box',
    borderRadius: '8px',
    fontStyle: 'normal',
    fontFamily: '$Inter',
    fontWeight: '500',
    fontSize: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    py: '0.375rem',
    px: '0.875rem',
    outline: 'none',
    border: 'none',
    lineHeight: '1.5',
    position: 'relative',
    textDecoration: 'none',
    width: 'fit-content',
    minWidth: '8rem',
    color: '$black',
    transition: 'all 100ms ease-in-out',
    willChange: 'transform, background-color, box-shadow',

    [`& ${Spinner}`]: {
        marginRight: '5px',
    },

    '&:focus': {
        outline: 0,
    },

    '&:disabled': {
        // opacity: 0.5,
        background: '$disableButton',
        color: 'white',
        boxShadow: 'none',
    },

    variants: {
        block: {
            true: {
                width: '100%',
            },
        },
        buttonType: {
            default: {
                background: '$primary',
                color: '$white',
                boxShadow: '0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 $primary',

                '&:hover': {
                    '&:not([disabled])': {
                        backgroundColor: '$primary500',
                    },
                },
            },
            outlined: {
                background: '$white',
                border: '1px solid #E5E5E5 !important',
                color: '$textDefault',

                '&:hover': {
                    '&:not([disabled])': {
                        border: '1px solid $primary !important',
                        color: '$primary',
                    },
                },
            },
            cancel: {
                background: '#E5DDFF',
                border: '1px solid #E5E5E5 !important',
                color: '$primary',

                '&:hover': {
                    '&:not([disabled])': {
                        border: '1px solid $primary !important',
                        color: 'white',
                        backgroundColor: '$primary500',
                    },
                },
            },
            text: {
                background: '$white',
                color: '$black',
                width: 'auto',
                minWidth: 'auto',
            },
            icon: {
                background: 'none',
            },
        },
    },
});

const BaseButton = styled('button', {
    ...BaseStyle,
});

const Anchor = styled(Link, {
    ...BaseStyle,
});

const Button: React.FC<ButtonProps> = (props) => {
    const {
        type = 'default',
        disabled = false,
        htmlType = 'button',
        children,
        block = false,
        loading = false,
        onClick,
        href,
        ...rest
    } = props;
    const handleClick = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>) => {
        if (onClick && !disabled && !loading) {
            onClick(e);
        }
    };

    const buttonNode = (
        <BaseButton type={htmlType} buttonType={type} onClick={handleClick} disabled={disabled} block={block} {...rest}>
            {loading && <Spinner width={14} />}
            {children}
        </BaseButton>
    );

    const anchorNode = (
        <Anchor to={href || ''} buttonType={type} onClick={handleClick} block={block} {...rest}>
            {loading && <Spinner width={14} />}
            {children}
        </Anchor>
    );
    return <>{href ? anchorNode : buttonNode}</>;
};

export default Button;
