import React, { useEffect, useState } from "react";
import { PracticeFrontSection } from "../../../../typings/interfaces/practice-front-node.interface";
import { styled } from "../../../../styles/stitches.config";
import { NODE_TYPES } from "../../../../typings/node-types.enum";
import useWebFontLoader from "../../../../hooks/useWebFontLoader";
import { values } from "lodash";
import useThemeStore from "../../../../stores/theme-store";

const Div = styled("div", {
  width: "100%",
  position: "relative",
  variants: {
    state: {
      editable: {},
      view: {
        border: 0,
      },
    },
    nodeType: {
      section: {},
      block: {},
    },
    showBackgroundSetting: {
      true: {
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      },
      false: {},
    },
    backgroundLight: {
      dark: {
        boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.4)",
      },
      light: {
        boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.1)",
      },
      none: {
        boxShadow: "none",
      },
    },
  },
});

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const DesignRenderer: React.FC<{
  node: PracticeFrontSection;
  children: any;
}> = ({ node, children }) => {
  const [paddingTopSpace, setPaddingTopSpace] =
    useState<string>("100px !important");
  const [paddingBottomSpace, setPaddingBottomSpace] =
    useState<string>("100px !important");
  const [backgroundColor, setBackgroundColor] = useState<string | null>(null);
  let styles: any = {},
    dynamicStyles: any = {},
    staticStyles: any = {};
  const [width, setWidth] = useState<number>(1200);
  useEffect(() => {
    let data = getWindowDimensions();
    setWidth(data.width);
  }, []);
  useWebFontLoader(
    node.design.styles &&
      node?.design?.styles["fontFamily"] && [
        node?.design?.styles["fontFamily"]?.value,
      ]
  );
  const backgroundLight =
    node.design.styles &&
    ((node.design?.styles["backgroundLight"] as any)?.value ||
      node.design?.styles["backgroundLight"]);

  useEffect(() => {
    const paddingTopLg =
      node.design.styles &&
      ((node.design?.styles["paddingTopLg"] as any)?.value ||
        node.design?.styles["paddingTopLg"]);
    const paddingTopMd =
      node.design.styles &&
      ((node.design?.styles["paddingTopMd"] as any)?.value ||
        node.design?.styles["paddingTopMd"]);
    const paddingTopSm =
      node.design.styles &&
      ((node.design?.styles["paddingTopSm"] as any)?.value ||
        node.design?.styles["paddingTopSm"]);

    const paddingBottomMd =
      node.design.styles &&
      ((node.design?.styles["paddingBottomMd"] as any)?.value ||
        node.design?.styles["paddingBottomMd"]);
    const paddingBottomSm =
      node.design.styles &&
      ((node.design?.styles["paddingBottomSm"] as any)?.value ||
        node.design?.styles["paddingBottomSm"]);
    const paddingBottomLg =
      node.design.styles &&
      ((node.design?.styles["paddingBottomLg"] as any)?.value ||
        node.design?.styles["paddingBottomLg"]);

    const backgroundColorSm =
      node.design.styles &&
      ((node.design?.styles["backgroundColorSm"] as any)?.value ||
        node.design?.styles["backgroundColorSm"]);

    if (width < 576) {
      setPaddingTopSpace((paddingTopSm || 20) + "px !important");
      setPaddingBottomSpace((paddingBottomSm || 20) + "px !important");
      if (backgroundColorSm) {
        setBackgroundColor(backgroundColorSm);
      }
    } else if (width < 991) {
      setPaddingTopSpace((paddingTopMd || 90) + "px !important");
      setPaddingBottomSpace((paddingBottomMd || 90) + "px !important");
      if (backgroundColorSm) {
        setBackgroundColor(backgroundColorSm);
      }
    } else {
      setPaddingTopSpace((paddingTopLg || 120) + "px !important");
      setPaddingBottomSpace((paddingBottomLg || 120) + "px !important");
    }
  }, [width, node.design.styles]);

  function extractDesign(temp: any, key: string) {
    const designs =
      typeof temp[key].value !== "object"
        ? temp[key].value + (temp[key].metadata?.postfix || "")
        : temp[key].value;
    return designs;
  }

  if (node.design.styles) {
    const temp = JSON.parse(JSON.stringify(node.design.styles));
    for (const key in temp) {
      if (temp[key].type) {
        if (!temp[key].isInner) dynamicStyles[key] = extractDesign(temp, key);
        delete temp[key];
      } else {
        staticStyles[key] = temp[key];
      }
    }
  }

  styles = { ...staticStyles, ...dynamicStyles, ...node?.animation?.styles };
  if (backgroundColor) {
    styles["backgroundColor"] = backgroundColor;
  }
  if (
    node.type === NODE_TYPES.SECTION &&
    node.path?.length === 1 &&
    node.generalSettings.type !== "Header" &&
    node.generalSettings.type !== "Footer"
  ) {
    if (backgroundColor) {
      styles["backgroundColor"] = backgroundColor;
    }
    styles["paddingTop"] = paddingTopSpace;
    styles["paddingBottom"] = paddingBottomSpace;
  }

  return (
    <Div
      backgroundLight={
        node.type === NODE_TYPES.SECTION &&
        node.path?.length === 1 &&
        backgroundLight
          ? backgroundLight
          : "none"
      }
      showBackgroundSetting={
        node.type === NODE_TYPES.SECTION && node.path?.length === 1
      }
      nodeType={"block"}
      css={{ ...styles, ...node.temp?.styles }}
      className={node.design.classes.join(" ") + " active"}
      key={node._id}
      id={node?.generalSettings.title?.toLocaleLowerCase().replace(/\s/g, "")}
    >
      {children}
    </Div>
  );
};

export default DesignRenderer;
