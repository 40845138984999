import React, {useMemo} from 'react';
import {BlockTypeProps} from '../../../../typings/interfaces/block-type-props';
import {styled} from "../../../../styles/stitches.config";
import {
    getInnerStyleConfiguration, getInnerStyleConfigurationLgV2,
    getInnerStyleConfigurationMdV2, getInnerStyleConfigurationSmV2, getInnerStyleConfigurationV2
} from "../../../../utils/styleConfiguration";

const SimpleTextTypography = styled('div', {
    textAlign: 'center',
    '@sm': {
        textAlign: 'left'
    }

})

const SimpleText: React.FC<BlockTypeProps> = ({blockNode}) => {

    const dynamicStyles = useMemo(() => {
        return blockNode?.design?.styles ? getInnerStyleConfigurationV2(blockNode.design.styles) : {};
    }, [blockNode?.design?.styles])

    const dynamicStylesLg = useMemo(() => {
        return blockNode?.design?.styles ? getInnerStyleConfigurationLgV2(blockNode.design.styles) : {};
    }, [blockNode?.design?.styles])

    const dynamicStylesMd = useMemo(() => {
        return blockNode?.design?.styles ? getInnerStyleConfigurationMdV2(blockNode.design.styles) : {};
    }, [blockNode?.design?.styles])


    const dynamicStylesSm = useMemo(() => {
        return blockNode?.design?.styles ? getInnerStyleConfigurationSmV2(blockNode.design.styles) : {};
    }, [blockNode?.design?.styles])

    const text = blockNode?.data?.text?.value || '';
    const createMarkup = (str: string) => {
        return {__html: str};
    };
    return (
        <SimpleTextTypography id={blockNode?._id}
                              as={blockNode?.metadata?.htmlTag || 'div'}
                              css={{
                                  ...dynamicStyles,
                                  ...dynamicStylesSm,
                                  '@md': {...dynamicStylesMd},
                                  '@lg': {...dynamicStylesLg}
                              }}
                              dangerouslySetInnerHTML={createMarkup(text)}
        />

    )
}

export default SimpleText;