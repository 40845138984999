import React, {useCallback, useEffect} from 'react'
import {styled} from "../../../../styles/stitches.config";
import {PracticeFrontBlock, ThemeSettings} from "../../../../typings/interfaces/practice-front-node.interface";
import useThemeStore from '../../../../stores/theme-store';
import ThemeStore from "../../../../stores/theme-store";
import { EASE_COLORS } from '../../../../constants/colors';

const Div = styled('div', {

    textAlign:'center',
    margin:'auto',
    padding:"7rem 1rem 0 1rem",
    '@xxs': {
        textAlign:'center',
        margin:'auto',
        padding:"7rem 1rem 0 1rem",
    },
    '@sm': {
        textAlign:'center',
        margin:'auto',
        padding:"7rem 1rem 0 1rem",
    },
    '@md': {
        maxWidth: '60%',
        padding: '2.5rem 2.5rem',
        textAlign:'left',
        height:'auto',
        margin:'6% 0',
        verticalAlign:'middle',
    },

    '@xl': {
        maxWidth: '60%',
        padding: '2.5rem 2.5rem',
        textAlign:'left',
        height:'auto',
        margin:'6% 0',
        verticalAlign:'middle',
    },

});
const Title=styled('div',{
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '22px',
    lineHeight: '32px',
    letter:'-1px',
    textShadow: '.1px .1px #ffffff',
    '@md':{
        fontWeight: 400,
        fontSize: '32px',
        lineHeight: '42px',
    },
    '@lg':{
        fontWeight: 400,
        fontSize: '70px',
        lineHeight: '76px',
    },
})
const SubTitle=styled('div',{
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '24px',
    textShadow: '.1px .1px #ffffff',
    marginTop:'1rem',
    '@lg':{
        marginTop:'2rem',
        width:'85%',
        fontSize: '24px',
        lineHeight: '36px',
    },

})

const NavButton = styled('button', {
    padding: '1rem 2.5rem',
    textDecoration: 'none',
    marginTop:'2rem',
    outline:'none',
    border:'none',
    cursor:'pointer',
});

interface HeroProps{
    blockNode: PracticeFrontBlock;
}
const Hero: React.FC<HeroProps> = ({blockNode}) => {
    const { theme} = ThemeStore( (state)=>({theme: state.theme}))

    let colorPalettes = theme?.themeSettings?.colorPalettes || {
        primary:  EASE_COLORS.PRIMARY_COLOR,
        secondary: EASE_COLORS.SECONDARY_COLOR,
        warn: EASE_COLORS.WARNING_COLOR,
        info:  EASE_COLORS.WHITE,
    }
    let fonts = theme?.themeSettings?.fonts || {
        title: 'Noto Serif',
        body: 'Work Sans',
        subtitle: 'Work Sans',
        paragraph: 'Noto Serif',
    };

    const {secondary,info}= colorPalettes as any;
    const {title: titleFont,body:bodyFont}= fonts as any;

    const {title,description, actionButtonText, actionButtonLink} = blockNode?.data;

    const handleAppointment =useCallback((link: string)=>{
           window.open(link)
    },[])

    return(
        <Div>
                <Title style={{ color: info,fontFamily:titleFont}}>{title?.value}</Title>
                <SubTitle  style={{color: info, fontFamily:bodyFont}}>{description?.value}</SubTitle>
                <NavButton  style={{backgroundColor: secondary, fontFamily:bodyFont}} onClick={()=> handleAppointment(actionButtonLink.value)}>{actionButtonText?.value}</NavButton>
        </Div>
    );
}
export default Hero;