import React, { useCallback } from 'react';
import blurEase from '../assets/images/blur_ease.svg';
import {styled} from "../styles/stitches.config";
import Button from "./Button";

const PageErrorWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    textAlign: 'center',
    width: '100%',
    p: '2rem',
    m: 'auto',

    '@md': {
        width: '50%',
    },
});

const ErrorImgContainer = styled('div', {
    // width: '100%',
    display: 'flex',
    justifyContent: 'center',
    p: '2rem',
    pb: '0',
});

const ErrorImg = styled('img', {
    size: '10rem',
});

const ErrorTitle = styled('div', {
    mt: 10,
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '30px',
    textAlign: 'center',
    letterSpacing: '-0.015em',
    color: '$TextSubdued',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
});

const ErrorSubTitle = styled('div', {
    pb: 20,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '24px',
    textAlign: 'center',
    letterSpacing: '-0.015em',
    color: '$TextSubdued',
});

interface PageErrorProps {
    title?: string;
    subTitle?: string;
    onCTAClick?: () => void;
    ctaButtonText?: string;
}

const PageError: React.FC<PageErrorProps> = ({
    title = 'Error!',
    subTitle = 'Sorry, something went wrong.',
    onCTAClick,
    ctaButtonText = 'Try Again',
}) => {
    const handleCTAClick = useCallback(() => {
        if (onCTAClick) {
            onCTAClick();
        } else {
            window.location.reload();
        }
    }, [onCTAClick]);

    return (
        <PageErrorWrapper>
            <ErrorImgContainer>
                <ErrorImg src={blurEase} alt="Error image" />
            </ErrorImgContainer>
            <ErrorTitle>{title}</ErrorTitle>
            <ErrorSubTitle>{subTitle}</ErrorSubTitle>
            <Button onClick={handleCTAClick}>{ctaButtonText}</Button>
        </PageErrorWrapper>
    );
};

export default PageError;
