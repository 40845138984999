import React from 'react';
import { styled } from '../../styles/stitches.config';

const Div = styled('div', {
    fontWeight: 500,
    fontSize: '1rem',
    textTransform: 'capitalize',
    color: '$textDefault',
    m: 0,
    p: 0,
    mb: '1em',
});

interface HeadingProps {
    children: React.ReactText;
}

const Heading: React.FC<HeadingProps> = ({ children }) => {
    return <Div>{children}</Div>;
};
export default Heading;
