import React, {Fragment} from 'react';
import {
    PracticeFrontNode,
    PracticeFrontRoot, PracticeFrontSection,PracticeFrontBlock
} from '../../../../typings/interfaces-v1/practice-front-node.interface';
import DesignRenderer from "../DesignRenderer";
import {NODE_TYPES} from "../../../../typings/node-types.enum";
import RootRenderer from "../RootRenderer";
import SectionRenderer from "../SectionRenderer";
import BlockRenderer from '../BlockRenderer';


// <node>
//     <design>
//         <animate>
//             <root></root>
//             <section></section>
//             <block></block>
//         </animate>
//     </design>
// </node>
// <root>
//     <color-themeApis>
//         <section></section>
//         <section></section>
//         <section></section>
//     </color-themeApis>
// </root>
// <section>
//     <layout>
//         <block></block>
//         <block></block>
//         <section></section>
//     </layout>
// </section>
// <block>
//     <about-me></about-me>
// </block>
// <about-me></about-me>
// <rich-text></rich-text>
// <image-slider></image-slider>
const  NodeRenderer:React.FC<{node:PracticeFrontNode}> =({node})=> {

    return (
        <Fragment>
              <DesignRenderer node={node as PracticeFrontSection}>
                    {node.type ===NODE_TYPES.ROOT ? <RootRenderer rootNode={node as PracticeFrontRoot}/> : ''}
                    {node.type ===NODE_TYPES.SECTION ? <SectionRenderer section={node as PracticeFrontSection}/> : ''}
                    {node.type ===NODE_TYPES.BLOCK ? <BlockRenderer block={node as PracticeFrontBlock}/> : ''}
              </DesignRenderer>
        </Fragment>
    );
}

export default NodeRenderer;
