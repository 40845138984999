import React from 'react'
import {styled} from "../../../../styles/stitches.config";
import {PracticeFrontBlock} from "../../../../typings/interfaces/practice-front-node.interface";
import SocialIcon from "../SocialIcon";
import {values} from "lodash";


const Div = styled('div', {
    display: 'flex',
    flexDirection: 'column',
});
const DesignStyle = styled('div', {
    display: 'flex',
    flexDirection:'column-reverse',
    textAlign:'center',
    alignItems:'center',

    '@md':{
        marginLeft:'auto',
        display: 'flex',
        flexDirection:'row',
        justifyContent: 'space-between',

    }
});

const IconHolder = styled('div', {
    display:'flex',
    alignItems:'center',
    justifyContent: 'space-around',
    marginBottom:'.5rem',
    '@md':{
        marginBottom:0,
    }
});
const Link = styled('div', {
    margin: '0 0.5rem',
});
interface HeroProps{
    blockNode: PracticeFrontBlock;
}
const FooterSocialLinks: React.FC<HeroProps> = ({blockNode}) => {
    const handleClick = (action:string) => {
        let url=action;
        if (!/^(http:|https:)/i.test(url)) url = "https://" + url;
        window.open(url);
    };
    const socials = values(blockNode?.data);
    return(
        <Div>
            <DesignStyle>
                <IconHolder>
                    {socials?.map((social, i)=> {
                        if (social?.value?.length > 0){
                            return <Link key={i} onClick={()=> handleClick(social.value)}><SocialIcon  url={social.value}/></Link>
                        }else {
                            return null;
                        }
                    })}
                </IconHolder>
            </DesignStyle>
        </Div>
    );
}
export default FooterSocialLinks;