import { PracticeFrontBlock } from "../typings/interfaces/practice-front-node.interface"

export function getInnerStyleConfiguration(blockNode: PracticeFrontBlock, dynamicStyles: any) {
    const temp = JSON.parse(JSON.stringify(blockNode.design.styles))
    for (const key in temp) {
        if (temp[key].type && temp[key].isInner) {
            dynamicStyles[key] = typeof (temp[key].value) !== 'object' ? temp[key].value + (temp[key].metadata?.postfix || '') : temp[key].value
        }
    }
}

export function getInnerStyleConfigurationV2(styles: any) {
    let dynamicStyles: any = {};
    const temp = JSON.parse(JSON.stringify(styles))
    for (const key in temp) {
        if (temp[key].type && temp[key].isInner && !temp[key]?.metadata?.rType) {
            dynamicStyles[key] = typeof (temp[key].value) !== 'object' ? temp[key].value + (temp[key].metadata?.postfix || '') : temp[key].value
        }
    }
    return dynamicStyles;
}


export function getInnerStyleConfigurationLgV2(styles: any) {
    let dynamicStyles: any = {};
    const temp = JSON.parse(JSON.stringify(styles));
    for (const key in temp) {
        if (temp[key].type && temp[key].isInner && temp[key]?.metadata?.rType === 'lg') {
            if (typeof (temp[key].value) !== 'object') {
                dynamicStyles[key.slice(0, -2)] = temp[key].value + (temp[key].metadata?.postfix || '')
            } else dynamicStyles[key.slice(0, -2)] = temp[key].value
        }
    }
    return dynamicStyles;
}

export function getInnerStyleConfigurationMdV2(styles: any) {
    let dynamicStyles: any = {};
    const temp = JSON.parse(JSON.stringify(styles))
    for (const key in temp) {
        if (temp[key].type && temp[key].isInner && temp[key]?.metadata?.rType === 'md') {
            if (typeof (temp[key].value) !== 'object') {
                dynamicStyles[key.slice(0, -2)] = temp[key].value + (temp[key].metadata?.postfix || '')
            } else dynamicStyles[key.slice(0, -2)] = temp[key].value

        }
    }
    return dynamicStyles;
}

export function getInnerStyleConfigurationSmV2(styles: any) {
    let dynamicStyles: any = {};
    const temp = JSON.parse(JSON.stringify(styles))
    for (const key in temp) {
        if (temp[key].type && temp[key].isInner && temp[key]?.metadata?.rType === 'sm') {
            if (typeof (temp[key].value) !== 'object') {
                dynamicStyles[key.slice(0, -2)] = temp[key].value + (temp[key].metadata?.postfix || '')
            } else dynamicStyles[key.slice(0, -2)] = temp[key].value
        }
    }
    return dynamicStyles;
}

