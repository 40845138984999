import create, {GetState, SetState} from 'zustand';
import produce from 'immer';
import {PIXEL_CONFIG, PracticeFrontNode} from "../typings/interfaces-v1/practice-front-node.interface";



type ThemeStore = {
    theme: PracticeFrontNode | null,
    setTheme: (theme: PracticeFrontNode) => void;
    selectedNode: PracticeFrontNode | null;
    setSelectedNode: (selectedBlock: PracticeFrontNode) => void;
    selectParentNode: (node: PracticeFrontNode) => void;
    updateNode: (node: PracticeFrontNode) => void;
    practiceFront: any;
    setPracticeFront: (practiceFront: any) => void;
    pixelSettings: PIXEL_CONFIG | null;
    setPixelSettings: (pixelSettings: PIXEL_CONFIG) => void;
};

const useThemeStore = create<ThemeStore>((set: SetState<ThemeStore>, get: GetState<ThemeStore>) => ({
    theme: null,
    practiceFront: null,
    setPracticeFront: (practiceFront: any) => set({practiceFront}),
    setTheme: (theme: PracticeFrontNode) => set({theme}),
    selectedNode: null,
    setSelectedNode: (selectedBlock: PracticeFrontNode) => set({selectedNode: selectedBlock}),
    selectParentNode: (node) => {
        const tree = get().theme
        const rootNode = tree;
        const blockPath = [...(node.path || [])];
        let parentNode = rootNode;
        while (blockPath.length > 1) {
            if (parentNode?.children?.length) {
                parentNode = parentNode.children[blockPath.shift() as number]
            }
        }
        if (parentNode) {
            get().setSelectedNode(parentNode);
        }
    },
    updateNode: (node: PracticeFrontNode) => {
        set(
            produce((state: ThemeStore) => {
                const nodePath = [...(node.path || get().selectedNode?.path || [])];
                const rootNode = state.theme as PracticeFrontNode;
                let parentNode = rootNode;
                while (nodePath.length > 1) {
                    if (parentNode?.children?.length) {
                        parentNode = parentNode.children[nodePath.shift() as number]
                    }
                }
                if (parentNode.children?.length) {
                    parentNode.children[nodePath.pop() as number] = node;
                }
                state.theme = rootNode as any;
            }),
        );

    },
    pixelSettings: null,
    setPixelSettings: (pixelSettings) => set({pixelSettings: pixelSettings})
}));

export default useThemeStore;
