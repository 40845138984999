import React, {Fragment, Suspense} from "react";
import {Route, Routes} from "react-router-dom";
import PageLoader from "./components/PageLoader";
import AppContainer from "./Modules/AppContainer";


const App: React.FC = () => {
    return (
        <Fragment>
            <Suspense fallback={<PageLoader/>}>
                <Routes>
                    <Route path="/" element={<AppContainer/>}/>
                    <Route path="/:page" element={<AppContainer/>}/>
                </Routes>
            </Suspense>
        </Fragment>
    );
};

export default App;
