import React from 'react';
import {styled} from "../../../../styles/stitches.config";
import { BlockTypeProps } from '../../../../typings/interfaces/block-type-props';
import ThemeStore from "../../../../stores/theme-store";
import { EASE_COLORS } from '../../../../constants/colors';
import { CheckCircleIcon } from '@easepractice/icons';

const CardContainer = styled('div', {
    border: '1px solid #1A1A1F',
    padding: '1rem',
    margin: '1rem 0',
});
const Row = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const Title = styled('div', {
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: '24px',
});
const Price = styled('div', {
    fontSize: '1.5rem',
    fontWeight: 600,
    lineHeight: '34px',
});
const CheckMark = styled(CheckCircleIcon, {
    marginRight: '0.3rem'
});

const PriceCard: React.FC<BlockTypeProps> = ({blockNode})=> {
    const { theme} = ThemeStore( (state)=>({theme: state.theme}))


    let colorPalettes = theme?.themeSettings?.colorPalettes || {
        primary:  EASE_COLORS.PRIMARY_COLOR,
        secondary: EASE_COLORS.SECONDARY_COLOR,
        warn: EASE_COLORS.WARNING_COLOR,
        info:  EASE_COLORS.WHITE,
    }
    let fonts = theme?.themeSettings?.fonts || {
        title: 'Noto Serif',
        body: 'Work Sans',
        subtitle: 'Work Sans',
        paragraph: 'Noto Serif',
    };

    const {primary, warn}= colorPalettes as any;
    const {body:bodyFont}= fonts as any;


    const {service, timeline, price} = blockNode?.data;

    return <CardContainer>
        <Row>
            <Title  style={{color: warn,fontFamily:bodyFont}} >
                {
                    service.value.length >0 &&  <><CheckMark />{service.value}</>
                }
            </Title>
            <Price style={{color: warn,fontFamily:bodyFont}} >Fees</Price>
        </Row>
        <Row>
            <Title  style={{color: warn,fontFamily:bodyFont}} >
                {
                    timeline.value.length > 0 &&  <><CheckMark /> {timeline.value}</>
                }
            </Title>
            <Price style={{color: primary,fontFamily:bodyFont}} >{price.value}</Price>
        </Row>
    </CardContainer>
}

export default PriceCard;