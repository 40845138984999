import React from 'react';
import {PracticeFrontBlock} from "../../../../typings/interfaces/practice-front-node.interface";
import { BlockTypeComponentMap } from '../../BlockTypes/BlockTypeComponentMap';

const  BlockRenderer:React.FC<{block:PracticeFrontBlock}> =({block})=> {
    const Component: React.FC<any> =  BlockTypeComponentMap[block.blockTypeId] as any;

    return (
        <>
            {
                Component ? <Component  blockNode={block} /> : <h1>{block.blockTypeId}</h1>
            }
        </>
    );
}

export default BlockRenderer;
