import { ArrowDown2Icon } from '@easepractice/icons';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import React, {CSSProperties} from 'react';
import {keyframes, styled} from "../../styles/stitches.config";

const slideDown = keyframes({
    from: { height: 0 },
    to: { height: 'var(--radix-accordion-content-height)' },
});

const slideUp = keyframes({
    from: { height: 'var(--radix-accordion-content-height)' },
    to: { height: 0 },
});

export const Accordion = styled(AccordionPrimitive.Root, {
    outline: 0,
    '&:focus-within': {
        outline: 0,
    },
    display: 'grid',
    gridRowGap: '1rem',
    // borderBottom: '1px solid #E5E5E5',
});

const StyledItem = styled(AccordionPrimitive.Item, {
    overflow: 'hidden',
    // background: '#FFFFFF',
    // borderBottom: '1px solid #E5E5E5',
    boxSizing: 'border-box',
    outline: 0,
    // margin: '1rem 0',

    // '&:first-child': {
    //     marginTop: 0,
    //     borderTopLeftRadius: 4,
    //     borderTopRightRadius: 4,
    // },

    // '&:last-child': {
    //     borderBottomLeftRadius: 4,
    //     borderBottomRightRadius: 4,
    // },

    '&:last-child': {
        border: 0,
    },
    '&:focus-within': {
        position: 'relative',
        zIndex: 1,
        // boxShadow: `0 0 0 2px ${mauve.mauve12}`,
        outline: 0,
    },
});

const StyledHeader = styled(AccordionPrimitive.Header, {
    all: 'unset',
    display: 'flex',
    outline: 0,
    position: 'relative',
    '&:focus-within': {
        outline: 0,
    },
    padding:'1rem 0',
    height:'auto',
    // minHeight: '100px',
});

const StyledTrigger = styled(AccordionPrimitive.Trigger, {
    all: 'unset',
    backgroundColor: 'transparent',
    padding: '1rem 1.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    outline: 0,
    '&:focus-within': {
        outline: 0,
    },
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '28px',
    cursor: 'pointer',
});

const StyledContent = styled(AccordionPrimitive.Content, {
    overflow: 'hidden',
    p: '1rem',

    '&[data-state="open"]': {
        animation: `${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
    },
    '&[data-state="closed"]': {
        animation: `${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
    },
    outline: 0,
    '&:focus-within': {
        outline: 0,
    },
});

const StyledChevron = styled(ArrowDown2Icon, {
    width:'24px',
    height:'24px',
    transition: 'transform 300ms cubic-bezier(0.87, 0, 0.13, 1)',
    '[data-state=open] &': { transform: 'rotate(180deg)' },
    outline: 0,
    '&:focus-within': {
        outline: 0,
    },
});

const Title= styled('div',{
    // marginRight: '1rem',
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: 600,
    breakWords:'break-all',
    textAlign:'left',
    left:0,
    width: '88%',
    '@md':{
        width: '95%',
    }


})
export const AccordionItem = StyledItem;
export const AccordionTrigger: React.FC<{ style:CSSProperties}> = ({style, children }) => (
    <StyledHeader as={'div'}>
        <StyledTrigger style={{ width: '100%', display: 'flex', justifyContent:"space-between" }}>
            <Title style={{...style}} >{children}</Title>
            <StyledChevron aria-hidden />
        </StyledTrigger>
    </StyledHeader>
);

export const AccordionContent: React.FC<{ style:CSSProperties}> = ({ style,children }) => (
    <StyledContent>
        <div style={{...style}}>{children}</div>
    </StyledContent>
);
