import React, {useCallback} from 'react';
import {PracticeFrontNode, PracticeFrontSection} from '../../../../typings/interfaces-v1/practice-front-node.interface';
import {styled} from "../../../../styles/stitches.config";
import useThemeStore from "../../../../stores/theme-store";
import {NODE_TYPES} from "../../../../typings/node-types.enum";

const Div = styled('div', {
    variants: {
        state: {
            editable: {
                '&:hover':{
                    border: '1px dashed',
                    boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                    cursor: 'pointer',
                }
            },
            view: {
                border: 0
            }
        },
        nodeType:  {
            section: {
                '&:hover':{
                    borderColor: '$error',
                }
            },
            block: {
                '&:hover':{
                    borderColor: '$warning50',
                }
            }
        }
    },
    border: '1px solid transparent'

});



const  DesignRenderer:React.FC<{node:PracticeFrontSection,children:any}> =({node,children})=> {
    const {setSelectedNode} = useThemeStore();

    const handleSelectBlock = useCallback((node: PracticeFrontNode)=>{
            setSelectedNode(node)
    }, []);
    return (
         <Div
             state={'view' }
             nodeType={ node?.type === NODE_TYPES.SECTION ?  'section' : 'block'  }
              // style={ node?.design.styles}
              onClick={(e)=>{
                  e.stopPropagation();
                  handleSelectBlock(node);
              }}
              style={{...node?.design.styles, ...node?.animation?.styles}}  className='active' key={node._id} id={node?.generalSettings.title?.toLocaleLowerCase()}>
             {children}
         </Div>
    );
}

export default DesignRenderer;
