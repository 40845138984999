import React, {useEffect, useState} from 'react';
import ReactPlayer from 'react-player';
import { styled ,CSS} from '../../../../styles/stitches.config';
import axios from "axios";
interface VideoProps {
    videoUrl: string | null;
    iconColor: string;
    size: 'SMALL' | 'MEDIUM' | 'LARGE';
    url?: string;
    dynamicStyles:CSS;
}

const VideoPlayerBox = styled('div', {
    backgroundColor: 'white',
    padding: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    position: 'relative',
    defaultVariants: {
        size: 'SMALL',
    },

    variants: {
        size: {
            SMALL: {
                height: 200,
                width: 200,
            },
            MEDIUM: {
                height: 300,
                width: 300,
            },
            LARGE: {
                height:'100%',
                width:'100%',
            },
        },
    },
});
function createMarkup(html: any) {
    return {__html: html};
}

const TiktokRenderer:React.FC<{url?:any}>=({url}) =>{
    const [tiktok,setTiktok]= useState<any>(null);

    useEffect(( )=>{
        axios.get(`https://www.tiktok.com/oembed?url=${url}`).then((response:any)=>{
            setTiktok(response.html)
        }).catch((e)=> console.error(e))
    },[url]);

    return <> {tiktok ?  <iframe
        srcDoc = {tiktok}
        sandbox = "allow-forms allow-same-origin allow-scripts allow-top-navigation"
    /> : <div/>} </>
}

const VideoPreview: React.FC<VideoProps> = ({dynamicStyles, videoUrl, size, iconColor}) => {
    // boxShadow: `0px 0px 0px .1px grey`,

    return (
        <>
            <VideoPlayerBox size={size} css={{ ...dynamicStyles }}>
                {videoUrl ? (
                    <ReactPlayer
                        light={true}
                        // playIcon={<img
                        //     src={'https://assets.website-files.com/61d349a2268b8a1801fcc09b/61de2b010754b17a7981ef2b_practicefront-edited%20(1).png'}
                        //     style={ { width:'150px' ,height:'150px'}}/>}
                        // style={{ pointerEvents: 'none' }}
                        className="react-player"
                        playing={false}
                        muted={true}
                        loop={true}
                        config={{
                            youtube: {
                                playerVars: {
                                    modestbranding: 1,
                                    showinfo: 0,
                                    fs: 0,
                                    controls: 0,

                                    rel: 0,
                                },
                            },
                        }}
                        controls={true}
                        height={ dynamicStyles?.height as number || 580}
                        width={'100%'}
                        url={videoUrl}
                        showinfo={0}
                        modestbranding={0}
                    />
                ) :  (
                    <span>No Video</span>
                )}
            </VideoPlayerBox>
        </>
    );
};

export default VideoPreview;
