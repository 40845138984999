import React, {Fragment} from 'react';
import {
    PracticeFrontNode,
    PracticeFrontRoot, PracticeFrontSection,PracticeFrontBlock
} from '../../../../typings/interfaces/practice-front-node.interface';
import DesignRenderer from "../DesignRenderer";
import {NODE_TYPES} from "../../../../typings/node-types.enum";
import RootRenderer from "../RootRenderer";
import SectionRenderer from "../SectionRenderer";
import BlockRenderer from '../BlockRenderer';
import CarouselRenderer from '../CarouselRenderer';
const  NodeRenderer:React.FC<{node:PracticeFrontNode}> =({node})=> {

    return (
        <Fragment>

              <DesignRenderer  node={node as PracticeFrontSection}>
                    {node.type ===NODE_TYPES.ROOT ? <RootRenderer rootNode={node as PracticeFrontRoot}/> : ''}
                    {node.type ===NODE_TYPES.SECTION ? <SectionRenderer section={node as PracticeFrontSection}/> : ''}
                  {node.type === NODE_TYPES.CAROUSEL ? <CarouselRenderer section={node as PracticeFrontSection}/> : ''}
                  {node.type ===NODE_TYPES.BLOCK ? <BlockRenderer block={node as PracticeFrontBlock}/> : ''}
              </DesignRenderer>
        </Fragment>
    );
}

export default NodeRenderer;
