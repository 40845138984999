import React, { useState } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import { styled } from "../../../../styles/stitches.config";
import { BlockTypeProps } from "../../../../typings/interfaces/block-type-props";

import Map, { Marker } from "react-map-gl";
import { getInnerStyleConfiguration } from "../../../../utils/styleConfiguration";

import mapboxgl from "mapbox-gl";
/* eslint-disable @typescript-eslint/no-var-requires */
(mapboxgl as any).workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default; // eslint-disable-line

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_PUBLIC_KEY || "";

const SimpleMap: React.FC<BlockTypeProps> = ({ blockNode }) => {
  let dynamicStyles: any = {};
  if (blockNode.design.styles) {
    getInnerStyleConfiguration(blockNode, dynamicStyles);
  }

  const [viewport, setViewport] = useState<any>({
    width: "100%",
    height: "100%",
    latitude: Number(blockNode?.data?.lat?.value),
    longitude: Number(blockNode?.data?.long?.value),
  });

  return (
    <Map
      boxZoom
      doubleClickZoom
      initialViewState={{
        ...viewport,
        zoom: Number(blockNode?.data?.zoom?.value) || 12,
      }}
      mapboxAccessToken={MAPBOX_TOKEN}
      mapLib={mapboxgl}
      {...viewport}
      onViewportChange={(nextViewport: any) => setViewport(nextViewport)}
      style={{ width: "100%", height: dynamicStyles?.height || 400 }}
      mapStyle="mapbox://styles/mapbox/light-v11"
      scrollZoom
      reuseMaps
    >
      <Marker
        color={dynamicStyles?.color || "red"}
        latitude={blockNode?.data?.lat?.value}
        longitude={blockNode?.data?.long?.value}
      />
    </Map>
  );
};

export default SimpleMap;
