import React from 'react';
import { BlockTypeProps } from '../../../../typings/interfaces/block-type-props';
import {styled} from "../../../../styles/stitches.config";
import {getInnerStyleConfiguration} from "../../../../utils/styleConfiguration";
import * as Separator from "@radix-ui/react-separator";

const Divider = styled(Separator.Root, {
    backgroundColor: '#E5E5E5',
    height: 1,
    width: '100%',
});


const SimpleDivider: React.FC<BlockTypeProps> = ({blockNode}) => {
    let dynamicStyles: any = {};
    if (blockNode.design.styles){
        getInnerStyleConfiguration(blockNode, dynamicStyles);
    }

    return (
        <Divider id={blockNode?._id}
                              as={blockNode?.metadata?.htmlTag || 'div'}
                              css={{...dynamicStyles}}
                             />

    )
}

export default SimpleDivider;