import create, {GetState, SetState} from 'zustand';
import produce from 'immer';
import {PIXEL_CONFIG, PracticeFrontNode} from "../typings/interfaces-v1/practice-front-node.interface";
import pixelTracking from "../components/PixelTracking";


export interface FormDataTypes {
    parentId: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    type?: string;
    message?:string;
}

type FormDataStore = {
    formData: FormDataTypes[] | null,
    resetFormData: (blockTypeId?: string) => void,
    updateData: (formData: FormDataTypes,type:string) => void;
    addData: (formData: FormDataTypes) => void;

};

const useFormDataStore = create<FormDataStore>((set: SetState<FormDataStore>, get: GetState<FormDataStore>) => ({
    formData: null,
    updateData: (formData,type:string) => {
        let oldData = get()?.formData || [];
        if (oldData?.length > 0) {
            const checkIsNew = oldData?.findIndex((item) => (item.parentId === formData.parentId));
            if (checkIsNew !== -1) {
                if (type === 'firstName') {
                    oldData[checkIsNew].firstName = formData.firstName
                } else if (type === 'lastName') {
                    oldData[checkIsNew].lastName = formData.lastName
                } else if (type === 'email') {
                    oldData[checkIsNew].email = formData.email
                } else if (type === 'phone') {
                    oldData[checkIsNew].phone = formData.phone
                }else if (type === 'message') {
                    oldData[checkIsNew].message = formData.message
                }
            } else oldData.push(formData)
        }
        else oldData.push(formData)
        set({formData: oldData})
    },
    addData: (formData) => {
        let oldData = get()?.formData || [];
        if (oldData?.length > 0) {
            const checkIsNew = oldData?.findIndex((e) => (e.parentId === formData.parentId));
            if (checkIsNew && checkIsNew > -1) {
                const editableData = oldData[checkIsNew]
                if (formData.type === 'firstName') {
                    editableData.firstName = formData.firstName
                } else if (formData.type === 'lastName') {
                    editableData.lastName = formData.lastName
                } else if (formData.type === 'email') {
                    editableData.email = formData.email
                } else if (formData.type === 'phone') {
                    editableData.phone = formData.phone
                }
                oldData[checkIsNew] = editableData;
            }
        } else oldData.push(formData)
        set({formData: oldData})
    },
    resetFormData: (parentId) => {
        let updatedData = null;
        if (parentId) {
            const all = get().formData;
            const newData = all?.filter((item) => item.parentId !== parentId)
            if (newData?.length) {
                updatedData = newData
            }
        }
        // console.log(updatedData, 'reseting stage...')
        set({
            formData: updatedData
        })
    }
}));

export default useFormDataStore;


export const getStoreValue = (formData: FormDataTypes[], parentId: string) => {
    const data:any = formData.find((item) => item.parentId === parentId )
    if(data.parentId){
        delete data.parentId;
    }
    return data;
}