import React from 'react';


const  ColorThemeRenderer:React.FC<{themeSettings:any,children:any}> =({themeSettings,children})=> {
    return (
        <>
            {children}
        </>
    );
}

export default ColorThemeRenderer;
