import Hero from "./HeroContent";
import FooterSocialLinks from "./FooterContent";
import SiteHeader from "./SiteHeader";
import ServiceSectionTitle from "./ServiceSection/serviceSectionTitle";
import FaqHeader from "./Faq/FaqHeader";
import FAQs from "./Faq/FAQs";
import AboutMeDetails from "./AboutMe/aboutMeDetails";
import AboutMeBanner from "./AboutMe/aboutMeBanner";
import ContactInfo from "./Contact/ContactInfo";
import Emergency from "./Emergency/Emergency";
import PricingHeader from "./Pricing/PricingHeader";
import ServiceSectionDetails from "./ServiceSection/serviceSectionDetails";
import SimpleOrderedList from "./SimpleOrderedList";
import PriceCard from "./Pricing/PriceCard";
import SimpleText from "./SimpleText";
import EmptySpace from "./EmptySpace";
import SimpleButton from "./SimpleButton";
import SimpleDescription from "./SimpleDescription";
import SimpleImage from "./SimpleImage";
import SimpleList from "./SimpleList";
import SimpleVideo from "./SimpleVideo";
import CoverImage from "./CoverImage/CoverImage";
import SimpleDivider from "./Divider";
import PdfLink from "./PdfLink";
import SimpleFaq from "./SimpleFaq";
import SimpleCustomButton from "./SimpleCustomButton";
import SimpleHamBurger from "./SimpleHamburger";
import SimpleInput from "./SimpleInput";
import SimpleEmail from "./SimpleEmail";
import ActionButton from "./ActionButton";
import InputWithButton from "./InputButton";
import ResponsiveText from "./ResponsiveText";
import Carousel from "./Carousel";
import SimpleMap from "./SimpleMap";

export const BlockTypeComponentMap: any =  {
    "service-section-details-t1": ServiceSectionDetails,
    "service-section-title": ServiceSectionTitle,
    "practice-front-hero": Hero,
    "practice-front-about-me-details": AboutMeDetails,
    "practice-front-about-me-banner": AboutMeBanner,
    "footer-social-links": FooterSocialLinks,
    "practice-front-header": SiteHeader,
    "practice-front-faq-header": FaqHeader,
    "accordian": FAQs,
    "practice-front-contact": ContactInfo,
    "practice-front-emergency": Emergency,
    "practice-front-pricing-header": PricingHeader,
    "price-card": PriceCard,
    "simple-ordered-list": SimpleOrderedList,
    "simple-text": SimpleText,
    "simple-button": SimpleButton,
    "empty-space": EmptySpace,
    "simple-description": SimpleDescription,
    "simple-image": SimpleImage,
    "simple-video": SimpleVideo,
    "simple-list": SimpleList,
    "cover-image": CoverImage,
    "simple-divider": SimpleDivider,
    "pdf-link": PdfLink,
    "simple-faq": SimpleFaq,
    "custom-button": SimpleCustomButton,
    "simple-hamburger": SimpleHamBurger,
    'simple-input': SimpleInput,
    'simple-email': SimpleEmail,
    'action-button': ActionButton,
    'email-with-button': InputWithButton,
    "responsive-text": ResponsiveText,
    "carousel": Carousel,
    "simple-map": SimpleMap
}